import { useState } from "react";

export const useDemoAccessModal = () => {
    const [open, setOpen] = useState(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    return {
        isOpen: open,
        openModal,
        closeModal
    }
}