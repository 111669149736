import { useCallback, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '4px',
    outline: 'none',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const DemoAccessModal = ({ isOpen, closeModal }) => {
    const navigate = useNavigate();

    const onClick = useCallback(() => {
        navigate('/access')
    }, [navigate]);

    const formLink = useMemo(() => {
        const currentHost = window.location.origin;
        return `${currentHost}/form`;
    }, [])

    return (
        <Modal
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Доступ ограничен
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Для получения доступа введите код или перейдите по ссылке.
                </Typography>
                <Grid container flexDirection='column' alignItems='center' mt={2} gap='10px'>
                    <Button variant="outlined" onClick={onClick}>
                        Ввести код доступа
                    </Button>
                    <a href={formLink}>Получить доступ</a>
                </Grid>
            </Box>
        </Modal>
    );
}