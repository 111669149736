import { Alert, Grid, LinearProgress } from "@mui/material";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { useEffect, useLayoutEffect, useMemo } from "react";
import { nanoid } from "nanoid";
import { getAnalyticsObjectsTree, getSDataChartTitle, prepareSData, prepareSDataV2, prepareSDataWithTree } from "../sDataPage.utils";
import { AnalyticsObjectsSelector } from "../../../components/analyticsObjectsSelector/analyticsObjectsSelector";
import { useSDataParams, useSDataSliderParams } from "../sDataPage.hooks";
import { sDataLinesDefaultParams, SDataLineParams } from "./sDataLineParams";
import { SDataChartType } from "../../../components/sDataChartType";
import { SDataFormulaSelector } from "../../../components/sDataFormulaSelector/sDataFormulaSelector";
import { AnalyticsObjectsTreeSelector } from "../../../components/analyticsObjectsSelector";
import { isColor } from "../../../utils/chart.utils";
import { useSDataLine } from "./sDataLine.hooks";
import { CopyHtmlGrid } from "../../../components/copyHtmlGrid";
import { setAnalyticsObjects } from "../../../store/reducer/sDataSlice";
import { useDispatch } from "react-redux";
import { addExportCountListener } from "../../../utils/listeners";
import { addExportMenu } from "../../../utils/exporting";

const gridParamsSx = {
    flexDirection: 'row',
    gap: '5px',
    height: '440px',
    flexWrap: "nowrap",
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        height: 'unset',
        // flexWrap: 'wrap'
    }
}

export const SDataLine = ({ data, compareSData, selectedAnalyticsObjects, formulaSData, indicator, isLoading }) => {
    const dispatch = useDispatch();
    const chartName = useMemo(() => `visualstat_${nanoid(10)}`, []);
    const { params, actions } = useSDataParams(sDataLinesDefaultParams);
    const sliderParams = useSDataSliderParams(data);

    const sumObjectName = useMemo(() => {
        if (!data?.indicator?.elementsName || !data?.indicator?.isSumValuesGraph) return undefined;
        return `Все ${data?.indicator?.elementsName}`;
    }, [data]);

    const isSumValue = useMemo(() => data?.indicator?.sumValue, [data]);
    const isSumValuesGraph = useMemo(() => data?.indicator?.isSumValuesGraph, [data]);

    const analyticsObjectsTree = useMemo(() => getAnalyticsObjectsTree(data?.analyticsObjects ?? []), [data]);
    const hasGroups = useMemo(() => {
        return analyticsObjectsTree?.some((item) => item.childrens.length > 0);
    }, [analyticsObjectsTree]);

    const { htmlDiagram, createChart } = useSDataLine(chartName,
        data,
        params,
        selectedAnalyticsObjects,
        compareSData,
        formulaSData,
        sliderParams,
    );

    const isFormulaWarning = useMemo(() => {
        if (!formulaSData) return false;
        const isWarning = formulaSData?.some((formulaItem) => {
            return formulaItem?.data?.every((item) => item.value === 0) && formulaItem.isGroup
        });
        return isWarning;
    }, [formulaSData]);

    useEffect(() => {
        if (!selectedAnalyticsObjects || !selectedAnalyticsObjects?.length) {
            if (sumObjectName) {
                const defaultAnalyticsObjects = [{ bitrixID: 0, name: sumObjectName }];
                dispatch(setAnalyticsObjects(defaultAnalyticsObjects));
            } else {
                const defaultAnalyticsObjects = [...data?.analyticsObjects]
                    ?.sort((a, b) => Number(a.sort) - Number(b.sort))
                    ?.slice(0, 1);
                dispatch(setAnalyticsObjects(defaultAnalyticsObjects));
            }
        }
    }, [data]);


    useLayoutEffect(() => {
        if (!data || isLoading) return;

        // const chartDataArray = prepareSData(data, selectedAnalyticsObjects, compareSData);
        // const chartDataArray = prepareSDataV2(data, selectedAnalyticsObjects, compareSData);
        const chartDataArray = prepareSDataWithTree(data, selectedAnalyticsObjects, compareSData, formulaSData, sliderParams);
        const chartTitle = getSDataChartTitle({ data, formulaSData });
        if (chartDataArray.length === 0) return;

        am5.addLicense("AM5C404071029");
        let root = am5.Root.new(chartName);

        root.locale["_decimalSeparator"] = ",";
        root.locale["_thousandSeparator"] = " ";

        root.setThemes([am5themes_Animated.new(root)]);

        const exportData = chartDataArray?.flatMap((item) => item?.seriesData);
        addExportMenu(root, exportData);
        // am5plugins_exporting.Exporting.new(root, {
        //     menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        //     dataSource: exportData,
        //     filePrefix: "statbase_chart",
        //     pngOptions: {
        //         quality: 0.9,
        //     },
        //     jpgOptions: {
        //         quality: 0.9,
        //     },
        // });

        let chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                ...(params.zoomEnable
                    ? {
                        wheelX: "panX",
                        wheelY: "zoomX",
                    }
                    : {}),
                pinchZoomX: true,
                layout: root.verticalLayout,
            })
        );

        if (params.showTitle) {
            chart.topAxesContainer.children.push(
                am5.Label.new(root, {
                    text: chartTitle,
                    fontSize: 20,
                    fontWeight: "400",
                    x: am5.p50,
                    centerX: am5.p50,
                })
            );
        }

        if (params.scrollbarEnable) {
            chart.set(
                "scrollbarX",
                am5.Scrollbar.new(root, { orientation: "horizontal" })
            );
        }

        let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        // Create Y-axis
        let yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
                ...(params?.startYAxisFromZero ?
                    {
                        min: 0,
                        strictMinMax: true,
                    } : {}
                ),
                forceHidden: params?.hideYAxis
            })
        );

        // Create X-Axis
        let xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: "columnName",
            })
        );

        // const allData = chartDataArray.flatMap((item) => item.seriesData);
        xAxis.data.setAll(chartDataArray?.[0]?.seriesData ?? []);

        chartDataArray.forEach((chartData) => {
            const { seriesName, seriesData } = chartData;
            const series = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: seriesName,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    categoryXField: "columnName",
                    minBulletDistance: 50,
                    sequencedInterpolation: true,
                    tooltip: am5.Tooltip.new(root, {
                        labelText: `{tooltipLabel} | {categoryX}: {valueY} {unit}`,
                    }),
                    connect: false,
                })
            );
            series.data.setAll(seriesData);
            series.appear(500);

            series.bullets.push(function () {
                var bulletCircle = am5.Circle.new(root, {
                    radius: 5,
                    fill: series.get("fill"),
                });
                return am5.Bullet.new(root, {
                    sprite: bulletCircle,
                });
            });
        });


        var yRenderer = yAxis.get("renderer");
        yRenderer.labels.template.setAll({
            fontSize: "0.7em",
            rotation: -60,
            centerY: am5.p50,
        });


        if (params.showLegend) {
            var legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.percent(50),
                    x: am5.percent(50),
                })
            );
            legend.data.setAll(chart.series.values);
        }

        addExportCountListener();
        return () => {
            root.dispose();
        };
    }, [
        chartName,
        data,
        params,
        selectedAnalyticsObjects,
        compareSData,
        formulaSData,
        sliderParams,
        isLoading
    ]);

    return (
        <>
            <Grid container sx={gridParamsSx}>
                {hasGroups && isSumValuesGraph ?
                    <AnalyticsObjectsTreeSelector
                        analyticsObjects={analyticsObjectsTree}
                        label={data?.indicator?.elementsName}
                        analyticsObjectsElements={data?.analyticsObjects}
                        sumObjectName={sumObjectName}
                    // hideElements={true}
                    /> :
                    <AnalyticsObjectsSelector
                        analyticsObjects={data?.analyticsObjects}
                        label={data?.indicator?.elementsName}
                        maxObjects={10}
                        sumObjectName={sumObjectName}
                    />
                }
                <SDataLineParams params={params} actions={actions} isNotCompare={data?.indicator?.isNotCompare} sliderParams={sliderParams} />
            </Grid>
            <SDataChartType isSumValue={isSumValue} isSumValuesGraph={isSumValuesGraph} />
            <SDataFormulaSelector indicator={indicator} />
            {/* {!selectedAnalyticsObjects.length && <Grid sx={{ fontSize: '22px', fontWeight: 600 }}>Объекты аналитики не выбраны</Grid>} */}
            {!selectedAnalyticsObjects.length &&
                <Alert variant="outlined" severity="warning">
                    Объекты аналитики не выбраны.
                </Alert>
            }
            {isFormulaWarning &&
                <Alert variant="outlined" severity="error">
                    Данный расчет недоступен для групп элементов.
                </Alert>
            }
            {isLoading && (
                <Grid width="100%">
                    <LinearProgress />
                </Grid>
            )}
            <Grid width={"100%"} container gap={"10px"}>
                <div
                    id={chartName}
                    style={{ width: params.width, height: params.height }}
                ></div>
                <CopyHtmlGrid htmlDiagram={htmlDiagram} createChart={createChart} />
            </Grid>
        </>
    );
};
