import { Button, Grid, IconButton, Link, TextField } from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { Padding } from "@mui/icons-material";
import { useGetOneCountryQuery } from "../../store/api/info.api";
import { useGetAppParams } from "../../hooks/app.hooks";
import { useMemo } from "react";

const headerSx = {
  pl: "60px",
  pt: "10px",
  pb: "10px",
  pr: "60px",
  justifyContent: "space-between",
  alignItems: "center",
  "@media (max-width: 600px)": {
    // pl: "10px",
    gap: '10px',
    justifyContent: "center",
  },
};

export const Header = () => {
  const { APP_PARAMS } = useGetAppParams();
  const { data: country } = useGetOneCountryQuery(APP_PARAMS?.defaultCountryId,
    { skip: !APP_PARAMS?.appIsLimited || !APP_PARAMS?.defaultCountryId }
  );

  const listLink = useMemo(() => {
    const currentHost = window.location.origin;
    return `${currentHost}/list/`;
  }, [])

  return (
    <Grid container sx={headerSx}>
      {/* <img src="https://statbase.ru/upload/CPriority/8ad/eirbhhxit174ieq0i8z26k8e1212041o/statbase%203.png" height={'30px'} /> */}
      {!APP_PARAMS?.appIsLimited && <img
        src="https://topic.ws/images/logos/visualstat_logo.png"
        height={"30px"}
      />}
      {APP_PARAMS?.appIsLimited && country?.flagUrl ?
        <img
          src={`https://topic.ws${country.flagUrl}`}
          height={"40px"}
        /> :
        <Grid width='80px' height='40px'></Grid>
      }
      <Link href={listLink} underline="none" target="_blank">
        Список показателей
      </Link>
    </Grid>
  );
};
