import { Chip, Grid, IconButton, LinearProgress, Tooltip } from "@mui/material";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import { Header } from "../../components/header";
import { useNavigate, useParams } from "react-router-dom";
import { tooltipClasses } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import {
  headerDescriptionTextSx,
  headerGridSx,
  headerTextSx,
} from "./mapGraph.styles";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { ChooseYear } from "../../components/chooseYear/chooseYear";
import { ChooseRegion } from "../../components/chooseRegion/chooseRegion";
import { VisualizationLinks } from "../../components/visualizationLinks/visualizationLinks";

import { nanoid } from "nanoid";
import { CopyHtmlGrid } from "../../components/copyHtmlGrid";
import { ChartTypeLinks } from "../../components/chartTypeLinks";
import {
  useGetDataByIndicatorQuery,
  useGetDataByYearQuery,
} from "../../store/api/vizualization.api";
import { useGetOneIndicatorQuery } from "../../store/api/info.api";
import { useSelector } from "react-redux";
import { prepareMapData } from "./mapGraph.utils";
import { MapGraphParams, useMapGraphParams } from "./mapGraphParams";
import { useMapGraphChart } from "./mapGraph.hooks";
import { useCheckAccess } from "../../hooks/app.hooks";
import { addExportCountListener } from "../../utils/listeners";
import { addExportMenu } from "../../utils/exporting";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

const vizualizationType = "map";
export const MapGraph = () => {
  const navigate = useNavigate();
  const chartName = useMemo(() => `visualstat_${nanoid(10)}`, []);
  const { indicatorId } = useParams();
  const selectedRegion = useSelector((state) => state.dataSlice.region);
  const setSelectedRegion = () => { };
  // const [selectedRegion, setSelectedRegion] = useState(null);
  const selectedYear = useSelector((state) => state.dataSlice.year);
  const setSelectedYear = () => { };
  // const [selectedYear, setSelectedYear] = useState(null);
  const { data: indicator } = useGetOneIndicatorQuery(indicatorId, {
    skip: !indicatorId,
  });
  // const { data: indicator } = useGetOneIndicator(indicatorId);
  const { data: dataByYear, isFetching: isLoading } =
    useGetDataByIndicatorQuery({ indicatorId, selectedYear, selectedRegion });
  // const { data: dataByYear, isLoading } = useGetDataByYear(indicatorId, selectedYear, selectedRegion);
  const { params, actions } = useMapGraphParams({ indicator });

  // useEffect(() => {
  //     const accessCode = localStorage.getItem('accessCode');
  //     const activationTime = localStorage.getItem('activationTime');
  //     if (!accessCode || !activationTime) {
  //         navigate('/demo-access');
  //     }
  // }, []);
  useCheckAccess();

  const title = useMemo(() => {
    const defaultYearLabel = indicator?.isOutputLastYearData
      ? dataByYear?.info?.currentYear ?? null
      : "Последний доступный год";
    const yearLabel =
      selectedYear?.label || defaultYearLabel
        ? `| ${selectedYear?.label ?? defaultYearLabel}`
        : "";
    return `${indicator?.name} ${yearLabel} | ${selectedRegion?.label ?? "Весь мир"
      }`;
  }, [indicator, selectedYear, selectedRegion, dataByYear]);
  const { htmlDiagram, createChart } = useMapGraphChart(
    chartName,
    dataByYear?.data,
    params,
    title
  );

  useLayoutEffect(() => {
    if (!dataByYear) return;
    const chartData = prepareMapData(dataByYear?.data, params);

    let root = am5.Root.new(chartName);

    root.locale["_decimalSeparator"] = ",";
    root.locale["_thousandSeparator"] = " ";

    var colors = am5.ColorSet.new(root, {});
    let data = chartData.map((item, index) => ({
      ...item,
      circleTemplate: { fill: colors.getIndex(index) },
    }));

    root.setThemes([am5themes_Animated.new(root)]);

    addExportMenu(root, chartData);
    // am5plugins_exporting.Exporting.new(root, {
    //   menu: am5plugins_exporting.ExportingMenu.new(root, {}),
    //   dataSource: chartData,
    //   filePrefix: "statbase_chart",
    //   pngOptions: {
    //     quality: 0.9,
    //   },
    //   jpgOptions: {
    //     quality: 0.9,
    //   },
    // });

    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    if (params.showTitle) {
      container.children.push(
        am5.Label.new(root, {
          text: title,
          fontSize: 20,
          fontWeight: "400",
          x: am5.p50,
          centerX: am5.p50,
        })
      );
    }

    let chart = container.children.push(
      am5map.MapChart.new(root, {
        panX: "rotateX",
        panY: "rotateY",
        projection: am5map.geoMercator(),
      })
    );

    var backgroundSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {})
    );
    backgroundSeries.mapPolygons.template.setAll({
      fill: root.interfaceColors.get("alternativeBackground"),
      fillOpacity: 0,
      strokeOpacity: 0,
    });

    backgroundSeries.data.push({
      geometry: am5map.getGeoRectangle(90, 180, -90, -180),
    });

    var polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      fill: root.interfaceColors.get("alternativeBackground"),
      fillOpacity: 0.15,
      strokeWidth: 0.5,
      stroke: root.interfaceColors.get("background"),
    });

    var circleTemplate = am5.Template.new({
      tooltipText: "{tooltipValue}: {value} {unit}",
    });

    var bubbleSeries = chart.series.push(
      am5map.MapPointSeries.new(root, {
        calculateAggregates: true,
        valueField: "value",
        polygonIdField: "id",
      })
    );

    bubbleSeries.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(
          root,
          {
            radius: 10,
            templateField: "circleTemplate",
          },
          circleTemplate
        ),
      });
    });

    bubbleSeries.set("heatRules", [
      {
        target: circleTemplate,
        min: 3,
        max: 30,
        key: "radius",
        dataField: "value",
      },
    ]);

    bubbleSeries.data.setAll(data);
    var cont = chart.children.push(
      am5.Container.new(root, {
        layout: root.horizontalLayout,
        x: 20,
        y: 40,
      })
    );

    cont.children.push(
      am5.Label.new(root, {
        centerY: am5.p50,
        text: "Map",
      })
    );

    var switchButton = cont.children.push(
      am5.Button.new(root, {
        themeTags: ["switch"],
        centerY: am5.p50,
        icon: am5.Circle.new(root, {
          themeTags: ["icon"],
        }),
      })
    );

    switchButton.on("active", function () {
      if (!switchButton.get("active")) {
        chart.set("projection", am5map.geoMercator());
        backgroundSeries.mapPolygons.template.set("fillOpacity", 0);
      } else {
        chart.set("projection", am5map.geoOrthographic());
        backgroundSeries.mapPolygons.template.set("fillOpacity", 0.1);
      }
    });

    cont.children.push(
      am5.Label.new(root, {
        centerY: am5.p50,
        text: "Globe",
      })
    );
    // let polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
    //     geoJSON: am5geodata_worldLow,
    //     exclude: ["AQ"]
    // }));
    // polygonSeries.mapPolygons.template.setAll({
    //     fill: root.interfaceColors.get("alternativeBackground"),
    //     fillOpacity: 0.15,
    //     strokeWidth: 0.5,
    //     stroke: root.interfaceColors.get("background")
    // });

    // let circleSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
    // circleSeries.mapPolygons.template.setAll({
    //     templateField: "polygonTemplate",
    //     tooltipText: "{tooltipValue}: {value}"
    // });

    // // circleSeries.data.pushAll(data);

    // let valueLow = Infinity;
    // let valueHigh = -Infinity;

    // for (var i = 0; i < data.length; i++) {
    //     let value = data[i].value;
    //     if (value < valueLow) {
    //         valueLow = value;
    //     }
    //     if (value > valueHigh) {
    //         valueHigh = value;
    //     }
    // }

    // let minRadius = 0.5;
    // let maxRadius = 5;

    // // Create circles when data for countries is fully loaded.
    // polygonSeries.events.on("datavalidated", function () {
    //     circleSeries.data.clear();

    //     for (var i = 0; i < data.length; i++) {
    //         let dataContext = data[i];
    //         let countryDataItem = polygonSeries.getDataItemById(dataContext.id);
    //         let countryPolygon = countryDataItem.get("mapPolygon");

    //         let value = dataContext.value;

    //         let radius = minRadius + maxRadius * (value - valueLow) / (valueHigh - valueLow);

    //         if (countryPolygon) {
    //             let geometry = am5map.getGeoCircle(countryPolygon.visualCentroid(), radius);
    //             circleSeries.data.push({
    //                 name: dataContext.name,
    //                 value: dataContext.value,
    //                 tooltipValue: dataContext.tooltipValue,
    //                 polygonTemplate: dataContext.polygonTemplate,
    //                 geometry: geometry
    //             });
    //         }
    //     }
    // })

    chart.appear(1000, 100);

    addExportCountListener();
    return () => {
      root.dispose();
    };
  }, [title, chartName, dataByYear, params]);

  return (
    <>
      <Header />
      <Grid container sx={headerGridSx}>
        <ChartTypeLinks type={vizualizationType} />
        <ChooseYear indicator={indicator} type={vizualizationType} />
        {!indicator ? (
          <LinearProgress />
        ) : (
          <>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              mb="10px"
            >
              <Grid container sx={headerTextSx}>
                {title}
                {indicator?.description && (
                  <NoMaxWidthTooltip
                    title={
                      <Grid sx={{ fontSize: "14px" }}>
                        {indicator?.description}
                      </Grid>
                    }
                  >
                    <IconButton>
                      <InfoRoundedIcon color="info" />
                    </IconButton>
                  </NoMaxWidthTooltip>
                )}
              </Grid>
              <Grid width="auto" container gap="5px">
                {indicator?.sources?.map((item) => (
                  <Chip label={item?.name} />
                ))}
              </Grid>
            </Grid>
            {indicator?.text && (
              <Grid container sx={headerDescriptionTextSx}>
                {indicator?.text}
              </Grid>
            )}
          </>
        )}
        <MapGraphParams params={params} actions={actions} />
        <ChooseRegion
          selectedRegion={selectedRegion}
          selectedYear={selectedYear}
          onChangeRegion={setSelectedRegion}
          onChangeYear={setSelectedYear}
          yearFrom={dataByYear?.info?.yearFrom}
          yearTo={dataByYear?.info?.yearTo}
          isOutputLastYearData={indicator?.isOutputLastYearData}
          defaultYear={dataByYear?.info?.currentYear}
          type={vizualizationType}
        />
        <VisualizationLinks
          showColumncountries={indicator?.isFullDataset}
          showPiechart={indicator?.sumValue?.value}
          type={vizualizationType}
        />
        {isLoading && <LinearProgress />}
        <Grid width={"100%"} container gap={"10px"}>
          <div
            id={chartName}
            style={{ width: params.width, height: params.height }}
          ></div>
          <CopyHtmlGrid htmlDiagram={htmlDiagram} createChart={createChart} />
        </Grid>
      </Grid>
    </>
  );
};
