import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import { BaseTestChart } from './pages/baseTestChart';
import { Default } from './pages/default';
import { TreemapGraph } from './pages/treemapGraph';
import { PieGraph } from './pages/pieGraph/pieGraph';
import { RaceGraph } from './pages/raceGraph/raceGraph';
import { ColumnsCountriesChart } from './pages/columnsCountriesChart';
import { MapGraph } from './pages/mapGraph';
import { DemoAccess } from './pages/demoAccess';
import { LinesChart } from './pages/linesChart/linesChart';
import { SDataPage } from './pages/sDataPage';
import { useGetAppParams } from './hooks/app.hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSdataParams } from './store/reducer/appSlice';



const router = createBrowserRouter([
  {
    path: "/indicator/:indicatorId/country/:countryId",
    element: <BaseTestChart />,
  },
  {
    path: "/indicator/:indicatorId/treemap",
    element: <TreemapGraph />
  },
  {
    path: "/indicator/:indicatorId/piechart",
    element: <PieGraph />
  },
  {
    path: "/indicator/:indicatorId/columncountries",
    element: <ColumnsCountriesChart />
  },
  {
    path: "/indicator/:indicatorId/race",
    element: <RaceGraph />
  },
  {
    path: "/indicator/:indicatorId/map",
    element: <MapGraph />
  },
  {
    path: "/indicator/:indicatorId/line",
    element: <LinesChart />
  },
  {
    path: "/access",
    element: <DemoAccess />
  },
  {
    path: "/indicator/:sDataIndicatorId/country/:countryId/sdata",
    element: <SDataPage />
  },
  {
    path: "*",
    element: <Default />,
  },
]);

const limitedRouter = createBrowserRouter([
  {
    path: "/indicator/:indicatorId",
    element: <BaseTestChart />,
  },
  {
    path: "/access",
    element: <DemoAccess />
  },
  {
    path: "/indicator/:sDataIndicatorId/sdata",
    element: <SDataPage />
  },
  {
    path: "*",
    element: <Default />,
  },
]);

function App() {
  const dispatch = useDispatch();
  const { APP_PARAMS } = useGetAppParams();

  const appRouter = APP_PARAMS.appIsLimited ? limitedRouter : router;

  useEffect(() => {
    if (APP_PARAMS.appIsLimited) {
      dispatch(setSdataParams({ indicator: APP_PARAMS.defaultIndicatorId }));
    }
  }, [APP_PARAMS]);

  return (
    <div className="App">
      {/* <header className="App-header">
      </header> */}
      {/* <BaseTestChart /> */}
      <RouterProvider router={appRouter} />
    </div>
  );
}

export default App;
