import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api.const";

const createUrlParams = (data) => {
  const { indicatorId, selectedYear, selectedRegion, fullDataset } = data;
  const organizationParams = selectedRegion?.id
    ? `&organization=${selectedRegion.id}`
    : "";
  const yearParams = selectedYear?.id
    ? `&year=${selectedYear.id}`
    : "&year=2023";
  const fullDatasetParams = fullDataset ? `&fullDataset=1` : "";
  return `get_data_by_year.php?indicator=${indicatorId}${yearParams}${organizationParams}${fullDatasetParams}`;
};
const createDataByIndicatorUrlParams = (data) => {
  const { indicatorId, selectedYear, selectedRegion, fullDataset } = data;
  const organizationParams = selectedRegion?.id
    ? `&organization=${selectedRegion.id}`
    : "";
  /**
   * selectedYear?.id !== 0
   * значение id = 0 зарезервировано
   * для вывода "последний доступный год"
   */
  const yearParams =
    selectedYear?.id && selectedYear?.id !== 0
      ? `&year=${selectedYear.id}`
      : "";
  const fullDatasetParams = fullDataset ? `&fullDataset=1` : "";
  return `get_data_by_indicator.php?indicator=${indicatorId}${yearParams}${organizationParams}${fullDatasetParams}`;
};
const createSDataByIndicatorUrlParams = (data) => {
  const { indicatorId, countryId, selectedYear, fullDataset } = data;
  const countryParams = countryId
    ? `&country=${countryId}`
    : "";
  /**
   * selectedYear?.id !== 0
   * значение id = 0 зарезервировано
   * для вывода "последний доступный год"
   */
  const yearParams =
    selectedYear?.id && selectedYear?.id !== 0
      ? `&year=${selectedYear.id}`
      : "";
  const fullDatasetParams = fullDataset ? `&fullDataset=1` : "";
  return `get_sdata.php?indicator=${indicatorId}${yearParams}${countryParams}${fullDatasetParams}`;
};

const createSDataByFormulaUrlParams = (data) => {
  const { indicatorId, countryId, formulaId, analyticObjectId, sproc, regval } = data;

  const formulaParams = formulaId
    ? `&frmreq=${formulaId}`
    : "";

  const analyticObjectParams = analyticObjectId
    ? `&prod=${analyticObjectId}`
    : "";
  const sprocParams = sproc
    ? `&sproc=${sproc}`
    : "";
  const regvalParams = regval
    ? `&regval=${regval}`
    : "";
  const regselParams = regval
    ? `&regsel=mo`
    : "";

  return `get_graph_country_f_v2.php?indicator=${indicatorId}&country=${countryId}${formulaParams}${analyticObjectParams}${sprocParams}${regvalParams}${regselParams}`;
};

export const vizualizationApi = createApi({
  reducerPath: "vizualizationApi",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    getDataByYear: builder.query({
      query: (data) => createUrlParams(data),
    }),
    getDataByIndicator: builder.query({
      query: (data) => createDataByIndicatorUrlParams(data),
    }),
    getDataByIndicatorCompare: builder.query({
      query: (data) => createDataByIndicatorUrlParams(data),
    }),
    getSDataByIndicator: builder.query({
      query: (data) => createSDataByIndicatorUrlParams(data),
    }),
    getSDataByFormula: builder.query({
      query: (data) => createSDataByFormulaUrlParams(data),
    }),
  }),
});

export const {
  useGetDataByYearQuery,
  useGetDataByIndicatorQuery,
  useGetDataByIndicatorCompareQuery,
  useGetSDataByIndicatorQuery,
  useGetSDataByFormulaQuery,
  useLazyGetSDataByFormulaQuery,
} = vizualizationApi;
