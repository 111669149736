import { defaultExportLimit, maxEportCount } from "./exporting";

const countListenerKeys = [
    'am5exporting-format-json',
    'am5exporting-format-csv',
    'am5exporting-format-xlsx',
    'am5exporting-format-pdfdata',
    'am5exporting-format-html',
];

const listenerKeysObject = {
    'json': 'am5exporting-format-json',
    'csv': 'am5exporting-format-csv',
    'xlsx': 'am5exporting-format-xlsx',
    'pdf': 'am5exporting-format-pdfdata',
    'html': 'am5exporting-format-html',
}

// const addCountListener = (key) => {
//     const element = document.querySelector(`.${key}`);
//     element?.addEventListener('click', (e) => console.log('click', e))
//     console.log("🚀 ~ addCountListener ~ element:", element)
// }

// export const addCountListeneres = () => {

//     // const exportMenu = document.querySelector('ul.am5exporting-list');
//     // const exportMenu2 = document.querySelector('li.am5exporting-format-json');
//     // // const exportMenu = document.querySelector('a.am5exporting');
//     // exportMenu.addEventListener('click', () => console.log('click'))
//     // exportMenu2?.addEventListener('click', () => console.log('click'))
//     countListenerKeys.forEach((key) => {
//         addCountListener(key);
//     });
// }

const incrementExportCount = (innerText) => {
    const innerTextArray = innerText.split(' ');
    if (innerTextArray[1] === 'Image') {
        return;
    }

    const exportLimit = localStorage.getItem("exportLimit") ?? defaultExportLimit;
    if (exportLimit === 'noLimit') {
        return;
    }

    const exportKey = innerTextArray[0]?.toLowerCase();

    const currentCount = localStorage.getItem(exportKey);
    const incrementedCount = Number(currentCount ?? 0) + 1;

    localStorage.setItem(exportKey, incrementedCount);

    if (incrementedCount >= Number(exportLimit)) {
        const listenerkey = listenerKeysObject[exportKey];
        const exportElement = document.querySelector(`li.${listenerkey}`);
        exportElement.style.display = 'none';
    }

    const isFirstExport = !Boolean(localStorage.getItem('hasExport'));
    if (isFirstExport) {
        localStorage.setItem('hasExport', 1);
        alert('Доступно не более 5 экспортов для каждого data формата')
    }
}

export const addExportCountListener = () => {
    const exportMenu = document.querySelector('ul.am5exporting-list');
    exportMenu.addEventListener('click', (event) => incrementExportCount(event.target.innerText))
}