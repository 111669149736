export const sDataPieDefaultParams = {
    width: "100%",
    height: window.innerWidth < 600 ? "300px" : "600px",
    sortDesc: false,
    sumOther: true,
    showTitle: true,
    showLegend: false,
    itemsCount: 10,
    groupsDisabled: false,
};
