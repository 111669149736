import { useCallback, useState } from "react";
import { getSDataChartTitle, getSDataTreemapYear, prepareSDataTreemapV3 } from "../sDataPage.utils";

export const useSDataTreemap = (
    chartName,
    data,
    params,
    selectedAnalyticsObjects,
    selectedYear,
) => {

    const [htmlDiagram, setHtmlDiagram] = useState('');

    const createChart = useCallback(() => {
        if (!data) return;
        const year = getSDataTreemapYear(data, selectedYear);
        // const chartDataArray = prepareSDataTreemap(data, year, params, selectedAnalyticsObjects);
        const chartDataArray = prepareSDataTreemapV3(data, year, params, selectedAnalyticsObjects);
        const chartTitle = getSDataChartTitle({ data, year });

        const chartData = [
            {
                'name': data?.indicator?.name,
                'children': chartDataArray
            }
        ];

        let strArray = [
            `<script src="https://cdn.amcharts.com/lib/5/index.js"></script>\r\n`,
            `<script src="//cdn.amcharts.com/lib/5/hierarchy.js"></script>\r\n`,
            `<script src="//cdn.amcharts.com/lib/5/themes/Animated.js"></script>\r\n`,
            `<div id="${chartName}" style="width: ${params.width}; height: ${params.height}"></div>\r\n`,
            `<script>\r\n`
        ];

        strArray.push(`\tvar jsonChartData = '${JSON.stringify(chartData)}';\r\n`);
        strArray.push(`\tvar chartData = JSON.parse(jsonChartData);\r\n`);

        strArray.push(`\tvar root = am5.Root.new('${chartName}');\r\n`);
        strArray.push(`\troot.setThemes([
            am5themes_Animated.new(root)
        ]);\r\n`);

        strArray.push(`\troot.locale["_decimalSeparator"] = ",";\r\n`);
        strArray.push(`\troot.locale["_thousandSeparator"] = " ";\r\n`);

        strArray.push(`\tvar container = root.container.children.push(
            am5.Container.new(root, {
                width: am5.percent(100),
                height: am5.percent(100),
                layout: root.verticalLayout,
            })
        );\r\n`);

        if (params.showTitle) {
            strArray.push(`\tcontainer.children.push(am5.Label.new(root, {
                text: '${chartTitle}',
                fontSize: 20,
                fontWeight: "400",
                x: am5.p50,
                centerX: am5.p50,
            }));\r\n`);
        }

        strArray.push(`\tvar series = container.children.push(
            am5hierarchy.Treemap.new(root, {
                categoryField: "columnName",
                valueField: "value",
                childDataField: "children",
                layoutAlgorithm: "binary",
                downDepth: 1,
                upDepth: 0,
                initialDepth: 1,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{tooltipLabel}: {sum} {unit}",
                }),
            })
        );\r\n`);

        strArray.push(`\tcontainer.children.moveValue(
            am5hierarchy.BreadcrumbBar.new(root, {
                series: series
            }), 0
        );\r\n`);
        strArray.push(`\tseries.data.setAll(chartData);\r\n`);
        strArray.push(`\tseries.set("selectedDataItem", series.dataItems[0]);\r\n`);
        strArray.push(`</script>`);
        setHtmlDiagram(strArray.join(''));
    }, [
        chartName,
        data,
        params,
        selectedAnalyticsObjects,
        selectedYear,
    ]);

    return { htmlDiagram, createChart }
}