import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Grid, IconButton, TextField } from "@mui/material";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { defaultExportLimit } from "../../utils/exporting";
import { useDemoAccessModal } from "../../modals/demoAccessModal/demoAccessModal.hooks";
import { DemoAccessModal } from "../../modals/demoAccessModal/demoAccessModal";
import { useGetLimits } from "../../hooks/app.hooks";

const exportKey = "htmlChart"
export const CopyHtmlGrid = ({ htmlDiagram, createChart }) => {
    const [exportDisabled, setExportDisabled] = useState(false);

    const { isDemoAccess } = useGetLimits();
    const { isOpen, openModal, closeModal } = useDemoAccessModal();

    const copyText = useCallback(() => {
        if (!htmlDiagram) return;
        navigator.clipboard.writeText(htmlDiagram);
    }, [htmlDiagram]);

    const createChartHandler = useCallback(() => {

        const currentCount = localStorage.getItem(exportKey);
        const incrementedCount = Number(currentCount ?? 0) + 1;

        localStorage.setItem(exportKey, incrementedCount);

        const isFirstExport = !Boolean(localStorage.getItem('hasExport'));
        if (isFirstExport) {
            localStorage.setItem('hasExport', 1);
            alert('Доступно не более 5 экспортов для каждого data формата')
        }

        const exportLimit = localStorage.getItem("exportLimit") ?? defaultExportLimit;
        if (exportLimit !== 'noLimit') {
            const exportCount = localStorage.getItem(exportKey) ?? 0;
            if (exportCount >= exportLimit) {
                setExportDisabled(true);
                return;
            }
        }

        createChart?.();

    }, [createChart]);

    useEffect(() => {
        const exportLimit = localStorage.getItem("exportLimit") ?? defaultExportLimit;
        if (exportLimit !== 'noLimit') {
            const exportCount = localStorage.getItem(exportKey) ?? 0;
            if (exportCount >= exportLimit) {
                setExportDisabled(true);
            }
        }
    }, []);

    return (
        <>
            <Grid container>
                <Grid container>
                    {isDemoAccess && <Grid><Button variant="outlined" onClick={openModal}>Показать код</Button></Grid>}
                    {!isDemoAccess && <Grid><Button disabled={exportDisabled} variant="outlined" onClick={createChartHandler}>Показать код</Button></Grid>}
                    <Grid>
                        <IconButton color="info" disabled={!htmlDiagram} onClick={copyText}>
                            <ContentCopyRoundedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {htmlDiagram && <TextField fullWidth multiline maxRows={10} value={htmlDiagram} />}
            </Grid>
            <DemoAccessModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
}
