import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    baseParams: {
        indicator: '12276',
        country: '6071',
    },
    baseSDataparams: {
        indicator: '505454',
        country: '6071',
    },
};

export const appSlice = createSlice({
    name: "app",
    reducerPath: "appSlice",
    initialState,
    reducers: {
        setParams: (state, action) => {
            const prevState = state.baseParams;
            state.baseParams = { ...prevState, ...action.payload };
        },
        setSdataParams: (state, action) => {
            const prevState = state.baseSDataparams;
            state.baseSDataparams = { ...prevState, ...action.payload };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setParams, setSdataParams } = appSlice.actions;
