export const prepareLinesData = (dataByYear, params, regionName) => {
  if (!dataByYear) return [];
  const { data, info } = dataByYear;
  const sumValue = params.sumValue;
  const unit = params.unit;
  const tooltipLabel = regionName ? `${regionName} | ` : "";

  let yearArray = [];
  for (let i = info?.yearFrom; i <= info?.yearTo; i++) {
    yearArray.push(i);
  }

  const resultData = yearArray.reduce((acc, currentYear) => {
    let count = 0;
    const summ = data?.reduce((acc, item) => {
      if (item.year === currentYear) {
        count++;
        return acc + item.value;
      }
      return acc;
    }, 0);

    const value = sumValue ? summ : count !== 0 ? summ / count : summ;
    const noZeroValue = value === 0 ? null : value;

    return [
      ...acc,
      { itemName: `${currentYear}`, value: noZeroValue, unit, tooltipLabel },
    ];
  }, []);

  return resultData;
};
