
export const filterItemByName = (str, treeItem) => {
    const elementName = treeItem?.name?.toLowerCase();
    const substr = str.toLowerCase();

    const elementIsIncludes = elementName.indexOf(substr) !== -1;

    if (treeItem?.childrens?.length > 0) {
        const childrensIsIncludes = treeItem?.childrens?.some((item) => filterItemByName(str, item));
        return childrensIsIncludes || elementIsIncludes;
    }

    return elementIsIncludes
}


export const getFilteredTree = (str, treeItems) => {
    if (!str || str === '') return treeItems;
    const substr = str.toLowerCase();

    const filteredTreeItems = treeItems?.reduce((acc, treeItem) => {
        const elementName = treeItem?.name?.toLowerCase();
        const elementIsIncludes = elementName.indexOf(substr) !== -1;

        if (treeItem?.childrens?.length > 0) {
            const filteredLocalTree = getFilteredTree(str, treeItem?.childrens);
            if (filteredLocalTree.length > 0 || elementIsIncludes) {
                const filteredTreeItem = { ...treeItem, childrens: filteredLocalTree };
                return [...acc, filteredTreeItem];
            }
        }

        if (elementIsIncludes) {
            return [...acc, treeItem];
        }
        return acc;
    }, []);

    return filteredTreeItems;
}

export const getFilteredElements = (str, elements) => {
    if (!str || str === '') return elements;
    const substr = str.toLowerCase();

    const filteredElements = elements?.filter((item) => {
        const elementName = item?.name?.toLowerCase();
        const elementIsIncludes = elementName.indexOf(substr) !== -1;
        return elementIsIncludes;
    });

    return filteredElements;
}