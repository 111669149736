import {
    Checkbox,
    Divider,
    Grid,
    IconButton,
    TextField,
    Tooltip,
} from "@mui/material";

import {
    mainGridSx,
    paramsSx,
    paramsTextField,
    paramsTitleSx,
} from "./sDataCategoriesParams.styles";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { SDataCompare } from "../../../../components/sDataCompare/sDataCompare";


export const SDataCategoriesParams = ({
    params,
    actions,
    isNotCompare
}) => {
    const { changeTextField, changeBoolField } = actions;

    return (
        <Grid display='flex' sx={mainGridSx}>
            <Grid container sx={paramsSx} width="65%">
                <Grid
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid container sx={paramsTitleSx}>
                        Настройки графика
                    </Grid>
                    <Grid>
                        <Tooltip title="Сбросить">
                            <IconButton onClick={actions.resetParams}>
                                <ClearRoundedIcon color="action" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container gap="10px" alignItems="baseline">
                    <TextField
                        sx={paramsTextField}
                        label="Ширина"
                        size="small"
                        value={params.width}
                        onChange={(e) => changeTextField("width", e)}
                    />
                    <TextField
                        sx={paramsTextField}
                        label="Высота"
                        size="small"
                        value={params.height}
                        onChange={(e) => changeTextField("height", e)}
                    />
                </Grid>
                <Grid container gap="10px" alignItems="baseline">
                    <TextField
                        sx={paramsTextField}
                        label="Цвет при наведении"
                        size="small"
                        value={params.hoverColor}
                        onChange={(e) => changeTextField("hoverColor", e)}
                    />
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.zoomEnable}
                        onClick={() => changeBoolField("zoomEnable")}
                    />
                    Включить масштабирование по оси X
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.scrollbarEnable}
                        onClick={() => changeBoolField("scrollbarEnable")}
                    />
                    Отображать полосу прокрутки
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.showTitle}
                        onClick={() => changeBoolField("showTitle")}
                    />
                    Отображать заголовок
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.showLegend}
                        onClick={() => changeBoolField("showLegend")}
                    />
                    Отображать легенду
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.startYAxisFromZero}
                        onClick={() => changeBoolField("startYAxisFromZero")}
                    />
                    Начинать ось Y с 0
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.hideYAxis}
                        onClick={() => changeBoolField("hideYAxis")}
                    />
                    Скрыть ось Y
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.sortByValue}
                        onClick={() => changeBoolField("sortByValue")}
                    />
                    Сортировать по значению
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.sortDesc}
                        onClick={() => changeBoolField("sortDesc")}
                    />
                    Сортировать в обратном порядке
                </Grid>
                <Grid container gap="10px" alignItems="center">
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={params.verticalXAxisLabels}
                        onClick={() => changeBoolField("verticalXAxisLabels")}
                    />
                    Названия на оси X вертикально
                </Grid>
            </Grid>
            {/* <Grid>
                <Divider orientation="vertical" />
            </Grid> */}
            <SDataCompare isNotCompare={isNotCompare} />
        </Grid>
    );
};
