import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyGetDemoAccessQuery } from "../../store/api/general.api";
import { useGetOneCountryQuery } from "../../store/api/info.api";
import { useGetAppParams } from "../../hooks/app.hooks";

export const DemoAccess = () => {
  const navigate = useNavigate();
  const { APP_PARAMS } = useGetAppParams();
  const [code, setCode] = useState(null);
  const [checkAccess] = useLazyGetDemoAccessQuery();

  const { data: country } = useGetOneCountryQuery(APP_PARAMS?.defaultCountryId,
    { skip: !APP_PARAMS?.appIsLimited || !APP_PARAMS?.defaultCountryId }
  );

  const logoSrc = useMemo(() => {
    if (!APP_PARAMS?.appIsLimited) {
      return "https://topic.ws/images/logos/visualstat_logo.png";
    }
    return `https://topic.ws${country?.logoCountryUrl ?? ''}`;
  }, [APP_PARAMS, country]);

  const checkAccessHandler = useCallback(() => {
    if (!code) return;
    void checkAccess(code)
      .unwrap()
      .then((res) => {
        const accessIsAllowed = res?.accessIsAllowed;
        const exportLimit = res?.exportLimit;
        if (accessIsAllowed) {
          const d = new Date();
          localStorage.setItem("activationTime", d.getTime());
          localStorage.setItem("accessCode", code);
          localStorage.setItem("exportLimit", exportLimit);
          navigate("/indicator/12276/country/6071");
        }
      });
  }, [code, checkAccess, navigate]);

  const formLink = useMemo(() => {
    const currentHost = window.location.origin;
    return `${currentHost}/form`;
  }, [])

  const clearStorage = useCallback(() => {
    localStorage.removeItem("activationTime");
    localStorage.removeItem("accessCode");
    localStorage.removeItem("exportLimit");
  }, []);

  return (
    <Grid
      container
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap="30px"
    >
      <img
        src={logoSrc}
        height={"30px"}
      />
      <Grid container flexDirection="column" gap="10px" width="300px">
        <TextField
          size="small"
          name="accessCode"
          label="Код доступа"
          onChange={(event) => {
            const value = event.target.value;
            setCode(value);
          }}
          value={code}
        />
        <Button variant="outlined" onClick={checkAccessHandler}>
          Ввести
        </Button>
        <a href={formLink}>Получить доступ</a>
      </Grid>
      {/* <Button variant="outlined" onClick={clearStorage}>Очистить</Button> */}
    </Grid>
  );
};
