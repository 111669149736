import {
    Checkbox,
    Divider,
    Grid,
    IconButton,
    Slider,
    TextField,
    Tooltip,
} from "@mui/material";

import {
    mainGridSx,
    paramsSx,
    paramsTextField,
    paramsTitleSx,
} from "./sDataRaceParams.styles";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";


export const SDataRaceParams = ({
    params,
    actions,
    sliderParams
}) => {
    const { changeTextField, changeBoolField } = actions;
    const { showSlider, min, max, marks, yearRange, changeSlider } = sliderParams;

    return (
        <Grid display='flex' sx={mainGridSx}>
            <Grid container sx={paramsSx} width="100%">
                <Grid
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid container sx={paramsTitleSx}>
                        Настройки графика
                    </Grid>
                    <Grid>
                        <Tooltip title="Сбросить">
                            <IconButton onClick={actions.resetParams}>
                                <ClearRoundedIcon color="action" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid container gap="10px" alignItems="baseline">
                    <TextField
                        sx={paramsTextField}
                        label="Ширина"
                        size="small"
                        value={params.width}
                        onChange={(e) => changeTextField("width", e)}
                    />
                    <TextField
                        sx={paramsTextField}
                        label="Высота"
                        size="small"
                        value={params.height}
                        onChange={(e) => changeTextField("height", e)}
                    />
                </Grid>
                <Grid container gap='10px' alignItems='baseline'>
                    <TextField type="number" sx={paramsTextField} label='Количество элементов' size="small" value={params.itemsCount} onChange={(e) => changeTextField('itemsCount', e)} />
                    <TextField type="number" sx={paramsTextField} label='Время анимации, сек' size="small" value={params.stepDuration} onChange={(e) => changeTextField('stepDuration', e)} />
                </Grid>
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.showTitle} onClick={() => changeBoolField('showTitle')} />
                    Отображать заголовок
                </Grid>
                {/* <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.showLegend} onClick={() => changeBoolField('showLegend')} />
                    Отображать легенду
                </Grid> */}
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.valuesInsideGraph} onClick={() => changeBoolField('valuesInsideGraph')} />
                    Значения внутри графика
                </Grid>
                <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.groupsDisabled} onClick={() => changeBoolField('groupsDisabled')} />
                    Отключить группировку
                </Grid>
                {!!showSlider && <Grid container width='300px' flexDirection='column' alignItems='flex-start' gap='5px' paddingLeft='10px'>
                    <Grid>Диапазон</Grid>
                    <Slider
                        min={min}
                        max={max}
                        value={yearRange}
                        onChange={changeSlider}
                        marks={marks}
                        valueLabelDisplay="auto"
                    />
                </Grid>}
            </Grid>
            {/* <Grid>
                <Divider orientation="vertical" />
            </Grid>
            <SDataCompare /> */}
        </Grid>
    );
};
