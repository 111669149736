export const sDataCategoriesDefaultParams = {
    width: "100%",
    height: window.innerWidth < 600 ? "300px" : "600px",
    showLegend: false,
    zoomEnable: true,
    scrollbarEnable: true,
    showTitle: true,
    startYAxisFromZero: false,
    hoverColor: '#297373',
    hideYAxis: false,
    sortByValue: false,
    sortDesc: false,
    verticalXAxisLabels: true,
};
