import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

export const defaultExportLimit = 0;

const getExportDisabled = (exportKey) => {
    const accessCode = localStorage.getItem("accessCode");
    const activationTime = localStorage.getItem("activationTime");
    if (!accessCode || !activationTime) return true;

    const exportLimit = localStorage.getItem("exportLimit") ?? defaultExportLimit;
    if (exportLimit === 'noLimit') return false;

    const currentCount = localStorage.getItem(exportKey);
    return Number(currentCount ?? 0) >= Number(exportLimit);
}

export const addExportMenu = (root, exportData) => {

    am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        dataSource: exportData,
        filePrefix: "visualstat_chart",
        pngOptions: {
            // disabled: getExportDisabled('png'),
            quality: 0.9,
        },
        jpgOptions: {
            // disabled: getExportDisabled('jpg'),
            quality: 0.9,
        },
        pdfOptions: {
            // disabled: getExportDisabled('png'),
        },
        jsonOptions: {
            disabled: getExportDisabled('json'),
        },
        csvOptions: {
            disabled: getExportDisabled('csv'),
        },
        xlsxOptions: {
            disabled: getExportDisabled('xlsx'),
        },
        pdfdataOptions: {
            disabled: getExportDisabled('pdf'),
        },
        htmlOptions: {
            disabled: getExportDisabled('html'),
        }
    });
}