import {
  Checkbox,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";
import {
  mainGridSx,
  paramsSx,
  paramsTextField,
  paramsTitleSx,
} from "./columnsCountriesChartParams.styles";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { ChooseCompareRegion, ChooseCompareRegionV2 } from "../../../components/chooseRegion";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

export const ColumnsCountriesChartParams = ({
  params,
  actions,
  compareRegion,
  onChangeCompareRegion,
  vizualizationType,
  compareRegionAvailable,
  indicator,
}) => {
  const { changeTextField, changeBoolField, changeNumberField } = actions;

  return (
    <Grid container sx={mainGridSx}>
      <Grid container sx={paramsSx} width="65%">
        <Grid
          container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid container sx={paramsTitleSx}>
            Настройки графика
          </Grid>
          <Grid>
            <Tooltip title="Сбросить">
              <IconButton onClick={actions.resetParams}>
                <ClearRoundedIcon color="action" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container gap="10px" alignItems="baseline">
          <TextField
            sx={paramsTextField}
            label="Ширина"
            size="small"
            value={params.width}
            onChange={(e) => changeTextField("width", e)}
          />
          <TextField
            sx={paramsTextField}
            label="Высота"
            size="small"
            value={params.height}
            onChange={(e) => changeTextField("height", e)}
          />
        </Grid>
        <Grid container gap="10px" alignItems="baseline">
          <TextField
            sx={paramsTextField}
            label="Цвет"
            size="small"
            value={params.color}
            onChange={(e) => changeTextField("color", e)}
          />
          {compareRegion && (
            <TextField
              sx={paramsTextField}
              label="Цвет сравнения"
              size="small"
              value={params.compareColor}
              onChange={(e) => changeTextField("compareColor", e)}
            />
          )}
          {/* <TextField
            sx={paramsTextField}
            label="Цвет при наведении"
            size="small"
            value={params.hoverColor}
            onChange={(e) => changeTextField("hoverColor", e)}
          /> */}
        </Grid>
        {/* <Grid container gap='10px' alignItems='baseline'>
                    <TextField type="number" sx={paramsTextField} label='Количество элементов' size="small" value={params.itemsCount} onChange={(e) => changeTextField('itemsCount', e)} />
                </Grid> */}
        <Grid container>
          <Grid container width='350px' gap="10px" alignItems="center" wrap="nowrap">
            <Checkbox
              sx={{ padding: 0 }}
              checked={params.zoomEnable}
              onClick={() => changeBoolField("zoomEnable")}
            />
            Включить масштабирование по оси X
          </Grid>
          <Grid container width='350px' gap="10px" alignItems="center" wrap="nowrap">
            <Checkbox
              sx={{ padding: 0 }}
              checked={params.scrollbarEnable}
              onClick={() => changeBoolField("scrollbarEnable")}
            />
            Отображать полосу прокрутки
          </Grid>
        </Grid>
        <Grid container>
          <Grid container width='350px' gap="10px" alignItems="center" wrap="nowrap">
            <Checkbox
              sx={{ padding: 0 }}
              checked={params.showLegend}
              onClick={() => changeBoolField("showLegend")}
            />
            Отображать легенду
          </Grid>
          <Grid container width='350px' gap="10px" alignItems="center" wrap="nowrap">
            <Checkbox
              sx={{ padding: 0 }}
              checked={params.showTitle}
              onClick={() => changeBoolField("showTitle")}
            />
            Отображать заголовок
          </Grid>
        </Grid>
        <Grid container>
          <Grid container width='350px' gap="10px" alignItems="center" wrap="nowrap">
            <Checkbox
              sx={{ padding: 0 }}
              checked={params.startYAxisFromZero}
              onClick={() => changeBoolField("startYAxisFromZero")}
            />
            Начинать ось Y с 0
          </Grid>
          <Grid container width='350px' gap="10px" alignItems="center" wrap="nowrap">
            <Checkbox
              sx={{ padding: 0 }}
              checked={params.hideYAxis}
              onClick={() => changeBoolField("hideYAxis")}
            />
            Скрыть ось Y
          </Grid>
        </Grid>
        {/* <Grid container gap='10px' alignItems='center'>
                    <Checkbox sx={{ padding: 0 }} checked={params.scrollbarEnable} onClick={() => changeBoolField('scrollbarEnable')} />
                    Отображать полосу прокрутки
                </Grid> */}
        {/* <Grid container gap='10px' alignItems='baseline'>
                        </Grid> */}
        {/* {!!yearRange && <Grid container width='300px' flexDirection='column' alignItems='flex-start' gap='5px' paddingLeft='10px'>
                    <Grid>Диапазон</Grid>
                    <Slider
                        // sx={{ width: '400px' }}
                        min={min}
                        max={max}
                        value={yearRange}
                        onChange={changeSlider}
                        marks={marks}
                        valueLabelDisplay="auto"
                    />
                </Grid>} */}
        {/* <Grid width={'300px'}><Slider min={1997} max={2024} value={[2014, 2020]} /></Grid> */}
      </Grid>
      <Grid>
        <Divider orientation="vertical" />
      </Grid>
      <ChooseCompareRegionV2
        compareRegionAvailable={compareRegionAvailable}
        indicatorId={indicator?.bitrixID}
      />
      {/* <ChooseCompareRegion
        selectedRegion={compareRegion}
        onChangeRegion={onChangeCompareRegion}
        type={vizualizationType}
        compareAvailable={compareAvailable}
      /> */}
    </Grid>
  );
};
