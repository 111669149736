import { Autocomplete, Button, Divider, Grid, IconButton, LinearProgress, TextField, Tooltip } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetIndicatorsQuery, useGetSDataIndicatorsQuery } from "../../store/api/dictionaries.api";
import { useDispatch, useSelector } from "react-redux";
import { addAnalyticsObject, resetAnalyticsObject, setAnalyticsObjects } from "../../store/reducer/sDataSlice";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { getFilteredElements } from "./analyticsObjectsSelector.utils";
import ClearIcon from '@mui/icons-material/Clear';

const gridSx = {
    display: 'flex',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px 0px 10px 5px',
    // gap: '10px',
    width: '25%',
    height: '100%',
    flexDirection: 'column',
    '@media (max-width: 600px)': {
        width: '100%',
        height: '450px',
    }
}

const listSx = {
    display: 'flex',
    // padding: '10px',
    flexDirection: 'column',
    overflowY: 'scroll'
}

const itemSx = {
    justifyContent: 'flex-start',
    textTransform: 'unset',
    textAlign: 'left',
    // whiteSpace: 'nowrap',

}


export const AnalyticsObjectsSelector = ({ analyticsObjects, label, maxObjects, sumObjectName }) => {
    const dispatch = useDispatch();
    const selectedAnalyticsObjects = useSelector((state) => state.sDataSlice.analyticsObjects);
    const [filteredStr, setFilteredStr] = useState(null);

    const onChangeHandler = useCallback((e) => {
        const value = e.target.value;
        setFilteredStr(value);
    }, [setFilteredStr]);

    const resetFilteredStr = useCallback((e) => {
        setFilteredStr('');
    }, [setFilteredStr]);

    const showSearchField = useMemo(() => analyticsObjects?.length >= 12, [analyticsObjects]);

    const selectIsDisabled = useMemo(() => {
        if (!maxObjects) return false;
        return selectedAnalyticsObjects.length >= maxObjects
    }, [selectedAnalyticsObjects, maxObjects]);

    const onClick = useCallback((analyticsObject) => {
        dispatch(addAnalyticsObject(analyticsObject));
    }, [dispatch]);

    const sortedAnalyticsObjects = useMemo(() => {
        const filteredTree = getFilteredElements(filteredStr, analyticsObjects);
        return [...filteredTree]?.sort((a, b) => Number(a.sort) - Number(b.sort));
    }, [analyticsObjects, filteredStr]);

    const title = useMemo(() => {
        if (!label) return 'Объекты аналитики';
        return `Объекты аналитики | ${label}`;
    }, [label]);

    const setAllHandler = useCallback(() => {
        if (sortedAnalyticsObjects?.length > 0) {
            dispatch(setAnalyticsObjects(sortedAnalyticsObjects));
        }
    }, [sortedAnalyticsObjects]);

    const resetHandler = useCallback(() => {
        dispatch(resetAnalyticsObject());
    }, [dispatch]);

    const sumObjectAlredySelected = useMemo(() =>
        selectedAnalyticsObjects?.find((selectedItem) => selectedItem.bitrixID === 0)
        , [selectedAnalyticsObjects]);

    return (
        <Grid sx={gridSx}>
            <Grid container justifyContent='center' alignItems='center'>
                <Tooltip title="Добавить все">
                    <IconButton size="small" onClick={setAllHandler}>
                        <AddCircleOutlineRoundedIcon color="success" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Удалить все">
                    <IconButton size="small" onClick={resetHandler}>
                        <HighlightOffRoundedIcon color="error" />
                    </IconButton>
                </Tooltip>
                <Grid fontSize='20px' fontWeight={600}>{title}</Grid>
            </Grid>
            {showSearchField && <Grid container pl='5px' pr='10px' mb='5px' mt='5px'>
                <TextField
                    fullWidth
                    size="small"
                    onChange={onChangeHandler}
                    value={filteredStr}
                    placeholder="Введите текст для поиска"
                />
                {filteredStr && filteredStr !== '' &&
                    <IconButton onClick={resetFilteredStr}>
                        <ClearIcon />
                    </IconButton>}
            </Grid>}
            <Grid sx={listSx}>
                {sumObjectName &&
                    <>
                        <Button
                            variant="text"
                            fullWidth
                            sx={itemSx}
                            color="inherit"
                            onClick={() => onClick({ bitrixID: 0, name: sumObjectName })}
                            disabled={selectIsDisabled || sumObjectAlredySelected}
                        >
                            {sumObjectName}
                        </Button>
                        <Divider />
                    </>
                }
                {sortedAnalyticsObjects?.map((item, index) => {
                    const showDivider = index + 1 < analyticsObjects.length;
                    const alredySelected = selectedAnalyticsObjects?.find((selectedItem) => selectedItem.bitrixID === item.bitrixID);
                    return <>
                        <Button
                            variant="text"
                            fullWidth
                            sx={itemSx}
                            color="inherit"
                            onClick={() => onClick(item)}
                            disabled={selectIsDisabled || alredySelected}
                        >
                            {item?.name}
                        </Button>
                        {showDivider && <Divider />}
                    </>
                })}
            </Grid>
            {/* <Grid display='flex' flexDirection='column' mt={2}>
                <Button fullWidth variant="outlined" size="small" onClick={setAllHandler}>Добавить все</Button>
                <Button fullWidth variant="outlined" size="small" onClick={resetHandler} color="error">Удалить все</Button>
            </Grid> */}
        </Grid>
    );
}
