import { Grid } from "@mui/material";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { useEffect, useLayoutEffect, useMemo } from "react";
import { nanoid } from "nanoid";
import { getAnalyticsObjectsTree, getSDataChartTitle, prepareSDataRace, prepareSDataRaceTree } from "../sDataPage.utils";
import { AnalyticsObjectsSelector, AnalyticsObjectsTreeSelector } from "../../../components/analyticsObjectsSelector";
import { useSDataParams, useSDataSliderParams } from "../sDataPage.hooks";
import { sDataRaceDefaultParams, SDataRaceParams } from "./sDataRaceParams";
import { SDataChartType } from "../../../components/sDataChartType";
import { SDataYearSelect } from "../../../components/sDataYearSelect";
import { useDispatch, useSelector } from "react-redux";
import { useSDataRace } from "./sDataRace.hooks";
import { CopyHtmlGrid } from "../../../components/copyHtmlGrid";
import { addExportCountListener } from "../../../utils/listeners";
import { addExportMenu } from "../../../utils/exporting";
import { setAnalyticsObjects } from "../../../store/reducer/sDataSlice";

const gridParamsSx = {
    flexDirection: 'row',
    gap: '5px',
    height: '360px',
    flexWrap: "nowrap",
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        height: 'unset',
        // flexWrap: 'wrap'
    }
}

export const SDataRace = ({ data, selectedAnalyticsObjects }) => {
    const dispatch = useDispatch();
    const chartName = useMemo(() => `visualstat_${nanoid(10)}`, []);
    const { params, actions } = useSDataParams(sDataRaceDefaultParams);
    const sliderParams = useSDataSliderParams(data);

    const analyticsObjectsTree = useMemo(() => getAnalyticsObjectsTree(data?.analyticsObjects ?? []), [data]);

    const hasGroups = useMemo(() => {
        return analyticsObjectsTree?.some((item) => item.childrens.length > 0);
    }, [analyticsObjectsTree]);

    const isSumValuesGraph = useMemo(() => data?.indicator?.isSumValuesGraph, [data]);

    const { htmlDiagram, createChart } = useSDataRace(
        chartName,
        data,
        params,
        selectedAnalyticsObjects,
        sliderParams,
    );

    useEffect(() => {
        if (!selectedAnalyticsObjects || !selectedAnalyticsObjects?.length) {
            if (!isSumValuesGraph) {
                const defaultAnalyticsObjects = [...data?.analyticsObjects]?.map((item) => ({
                    bitrixID: item.bitrixID,
                    name: item.name,
                    sort: item.sort
                }))
                    ?.sort((a, b) => Number(a.sort) - Number(b.sort))
                    ?.slice(0, 10);
                dispatch(setAnalyticsObjects(defaultAnalyticsObjects));
            }
        }
    }, [data, isSumValuesGraph]);

    useLayoutEffect(() => {
        if (!data) return;
        // const { chartData, yearFrom, yearTo } = prepareSDataRace(data, params, selectedAnalyticsObjects);
        const { chartData, yearFrom, yearTo } = prepareSDataRaceTree(data, params, selectedAnalyticsObjects, sliderParams);
        const chartTitle = getSDataChartTitle({ data });

        const unit = data?.indicator?.unit ?? '';

        am5.addLicense("AM5C404071029");
        let root = am5.Root.new(chartName);

        root.locale["_decimalSeparator"] = ",";
        root.locale["_thousandSeparator"] = " ";

        root.setThemes([am5themes_Animated.new(root)]);
        root.numberFormatter.setAll({
            numberFormat: "#a",

            bigNumberPrefixes: [
                { number: 1e6, suffix: "млн." },
                { number: 1e9, suffix: "млрд." },
            ],

            // Do not use small number prefixes at all
            smallNumberPrefixes: [],
        });

        addExportMenu(root, chartData);
        // am5plugins_exporting.Exporting.new(root, {
        //     menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        //     dataSource: chartData,
        //     filePrefix: "statbase_chart",
        //     pngOptions: {
        //         quality: 0.9,
        //     },
        //     jpgOptions: {
        //         quality: 0.9,
        //     },
        // });

        var stepDuration = params?.stepDuration ? Number(params?.stepDuration) * 1000 : 2000;
        root.setThemes([am5themes_Animated.new(root)]);
        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "none",
                wheelY: "none",
                paddingLeft: 0,
            })
        );

        if (params.showTitle) {
            chart.topAxesContainer.children.push(
                am5.Label.new(root, {
                    text: chartTitle,
                    fontSize: 20,
                    fontWeight: "400",
                    x: am5.p50,
                    centerX: am5.p50,
                })
            );
        }

        chart.zoomOutButton.set("forceHidden", true);
        var yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 20,
            inversed: true,
            minorGridEnabled: true,
        });
        yRenderer.grid.template.set("visible", false);
        var yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: "network",
                renderer: yRenderer,
            })
        );
        var xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                maxDeviation: 0,
                min: 0,
                strictMinMax: true,
                extraMax: 0.1,
                renderer: am5xy.AxisRendererX.new(root, {}),
            })
        );
        xAxis.set("interpolationDuration", stepDuration / 10);
        xAxis.set("interpolationEasing", am5.ease.linear);
        var series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "value",
                categoryYField: "network",
            })
        );
        series.columns.template.setAll({ cornerRadiusBR: 5, cornerRadiusTR: 5 });
        series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });
        series.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });
        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 1,
                sprite: am5.Label.new(root, {
                    text: `{valueXWorking.formatNumber('#.# a')} ${unit}`,
                    ...(params?.valuesInsideGraph
                        ? {
                            fill: root.interfaceColors.get("alternativeText"),
                            centerX: am5.p100,
                        }
                        : {}),
                    centerY: am5.p50,
                    populateText: true,
                }),
            });
        });
        var label = chart.plotContainer.children.push(
            am5.Label.new(root, {
                text: "2014",
                fontSize: "8em",
                opacity: 0.2,
                x: am5.p100,
                y: am5.p100,
                centerY: am5.p100,
                centerX: am5.p100,
            })
        );

        function getSeriesItem(category) {
            for (var i = 0; i < series.dataItems.length; i++) {
                var dataItem = series.dataItems[i];
                if (dataItem.get("categoryY") == category) {
                    return dataItem;
                }
            }
        }

        // Axis sorting
        function sortCategoryAxis() {
            // sort by value
            series.dataItems.sort(function (x, y) {
                return y.get("valueX") - x.get("valueX"); // descending
                //return x.get("valueX") - y.get("valueX"); // ascending
            });

            // go through each axis item
            am5.array.each(yAxis.dataItems, function (dataItem) {
                // get corresponding series item
                var seriesDataItem = getSeriesItem(dataItem.get("category"));

                if (seriesDataItem) {
                    // get index of series data item
                    var index = series.dataItems.indexOf(seriesDataItem);
                    // calculate delta position
                    var deltaPosition =
                        (index - dataItem.get("index", 0)) / series.dataItems.length;
                    // set index to be the same as series data item index
                    if (dataItem.get("index") != index) {
                        dataItem.set("index", index);
                        // set deltaPosition instanlty
                        dataItem.set("deltaPosition", -deltaPosition);
                        // animate delta position to 0
                        dataItem.animate({
                            key: "deltaPosition",
                            to: 0,
                            duration: stepDuration / 2,
                            easing: am5.ease.out(am5.ease.cubic),
                        });
                    }
                }
            });
            // sort axis items by index.
            // This changes the order instantly, but as deltaPosition is set, they keep in the same places and then animate to true positions.
            yAxis.dataItems.sort(function (x, y) {
                return x.get("index") - y.get("index");
            });
        }

        var year = yearFrom;

        var interval = setInterval(function () {
            year++;

            if (year > yearTo) {
                clearInterval(interval);
                clearInterval(sortInterval);
            }

            updateData();
        }, stepDuration);

        var sortInterval = setInterval(function () {
            sortCategoryAxis();
        }, 100);

        function setInitialData() {
            var d = chartData[year];

            for (var n in d) {
                series.data.push({ network: n, value: d[n] });
                yAxis.data.push({ network: n });
            }
        }

        function updateData() {
            var itemsWithNonZero = 0;

            if (chartData[year]) {
                label.set("text", year.toString());

                am5.array.each(series.dataItems, function (dataItem) {
                    var category = dataItem.get("categoryY");
                    var value = chartData[year][category];

                    if (value > 0) {
                        itemsWithNonZero++;
                    }

                    dataItem.animate({
                        key: "valueX",
                        to: value,
                        duration: stepDuration,
                        easing: am5.ease.linear,
                    });
                    dataItem.animate({
                        key: "valueXWorking",
                        to: value,
                        duration: stepDuration,
                        easing: am5.ease.linear,
                    });
                });

                yAxis.zoom(0, itemsWithNonZero / yAxis.dataItems.length);
            }
        }

        setInitialData();
        setTimeout(function () {
            year++;
            updateData();
        }, 50);

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        chart.appear(1000, 100);

        addExportCountListener();
        return () => {
            root.dispose();
        };
    }, [
        chartName,
        data,
        params,
        selectedAnalyticsObjects,
        sliderParams,
    ]);

    return (
        <>
            <Grid container flexDirection='row' gap='5px' height='360px' wrap="nowrap">
                {/* <AnalyticsObjectsSelector
                    analyticsObjects={data?.analyticsObjects}
                    label={data?.indicator?.elementsName}
                /> */}
                {hasGroups && isSumValuesGraph ?
                    <AnalyticsObjectsTreeSelector
                        analyticsObjects={analyticsObjectsTree}
                        label={data?.indicator?.elementsName}
                        // analyticsObjectsElements={data?.analyticsObjects}
                        // sumObjectName={sumObjectName}
                        hideElements={true}
                    /> :
                    <AnalyticsObjectsSelector
                        analyticsObjects={data?.analyticsObjects}
                        label={data?.indicator?.elementsName}
                    // maxObjects={10}
                    // sumObjectName={sumObjectName}
                    />
                }
                {/* {hasGroups && isSumValuesGraph && <AnalyticsObjectsTreeSelector
                    analyticsObjects={analyticsObjectsTree}
                    label={data?.indicator?.elementsName}
                    hideElements={true}
                />} */}
                <SDataRaceParams params={params} actions={actions} sliderParams={sliderParams} />
            </Grid>
            <SDataChartType isSumValue={data?.indicator?.sumValue} isSumValuesGraph={data?.indicator?.isSumValuesGraph} />
            <Grid width={"100%"} container gap={"10px"}>
                <div
                    id={chartName}
                    style={{ width: params.width, height: params.height }}
                ></div>
                <CopyHtmlGrid htmlDiagram={htmlDiagram} createChart={createChart} />
            </Grid>
        </>
    );
};
