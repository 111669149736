import { Autocomplete, Button, Grid, LinearProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetCountriesQuery, useGetIndicatorsByCountryQuery } from "../../store/api/dictionaries.api";
import { useDispatch } from "react-redux";
import { setParams } from "../../store/reducer/appSlice";
import { useGetAppParams } from "../../hooks/app.hooks";

const gridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    gap: '10px',
}

const autocompleteSxCountry = {
    width: '300px',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

const autocompleteSxIndicator = {
    width: '450px',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

const buttonSx = {
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

export const CountryAndIndicatorChoose = ({ indicator, country, beforeRedirectHandler }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { APP_PARAMS } = useGetAppParams();

    const [selectedIndicator, setSelectedIndicator] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(undefined);

    const { data: countries, isFetching: countriesIsLoading } = useGetCountriesQuery();
    const { data: indicators, isFetching: indicatorsIsLoading } = useGetIndicatorsByCountryQuery(selectedCountry?.id ?? country?.bitrixID, {
        skip: !country?.bitrixID && !selectedCountry?.id
    });

    const indicatorDictionary = useMemo(() => {
        if (!indicators) return [];
        return indicators?.map((item) => ({ id: item.bitrixID, label: item.name }));
    }, [indicators]);

    const countryDictionary = useMemo(() => {
        if (!countries) return [];
        return countries
            ?.filter((item) => !item.isOutputDatasetsOnly)
            ?.map((item) => ({ id: item.bitrixID, label: item.name }));
    }, [countries]);

    const redirect = useCallback(() => {
        if (!selectedIndicator?.id && !selectedCountry?.id) return;
        const indicatorId = selectedIndicator?.id ?? indicator?.bitrixID;
        const countryId = selectedCountry?.id ?? country?.bitrixID;
        beforeRedirectHandler?.()
        dispatch(setParams({ indicator: indicatorId, country: countryId }));
        if (APP_PARAMS.appIsLimited) {
            navigate(`/indicator/${indicatorId}`);
        } else {
            navigate(`/indicator/${indicatorId}/country/${countryId}`);
        }
    }, [beforeRedirectHandler, indicator, country, selectedIndicator, selectedCountry, dispatch, APP_PARAMS]);

    const hasLoader = useMemo(() => {
        if (!indicator || !country) return true;
        if (countriesIsLoading || indicatorsIsLoading) return true;
        return false;
    }, [indicator, country, countriesIsLoading, indicatorsIsLoading]);

    const hasData = useMemo(() => {
        if (!indicator || !country) return false;
        return true;
    }, [indicator, country]);

    useEffect(() => {
        if (indicatorDictionary.length > 0) {
            const currentIndicator = selectedIndicator ?? { id: indicator?.bitrixID, label: indicator?.name };
            const findedIndicator = indicatorDictionary.find((item) => item.id == currentIndicator.id);
            if (!findedIndicator) {
                setSelectedIndicator(null);
                // const ele = indicatorAutocompleteRef.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
                // ele?.click();
            }
            // else {
            //     setSelectedIndicator(findedIndicator);
            // }
        }
    }, [indicatorDictionary])

    useEffect(() => {
        setSelectedIndicator({ id: indicator?.bitrixID, label: indicator?.name })
    }, [indicator])

    return (
        <Grid container sx={gridSx}>
            {hasLoader && <Grid width='100%'><LinearProgress /></Grid>}
            {hasData && <>
                {!APP_PARAMS.appIsLimited && <Autocomplete
                    size="small"
                    onChange={(_, value) => setSelectedCountry(value)}
                    options={countryDictionary}
                    value={selectedCountry}
                    disabled={countriesIsLoading}
                    defaultValue={{ id: country.bitrixID, label: country.name }}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    sx={autocompleteSxCountry}
                    renderInput={(params) => <TextField {...params} label="Страна" />}
                />}
                <Autocomplete
                    size="small"
                    onChange={(_, value) => setSelectedIndicator(value)}
                    options={indicatorDictionary}
                    value={selectedIndicator}
                    disabled={indicatorsIsLoading}
                    defaultValue={{ id: indicator.bitrixID, label: indicator.name }}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    sx={autocompleteSxIndicator}
                    renderInput={(params) => <TextField {...params} label="Индикатор" />}
                />
                <Button
                    variant="outlined"
                    color="info"
                    disabled={!selectedIndicator && !selectedCountry}
                    onClick={redirect}
                    sx={buttonSx}
                >
                    Перейти
                </Button>
            </>}
        </Grid>
    );
}
