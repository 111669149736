import { Autocomplete, Button, Grid, LinearProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllIndicators } from "./chooseYear.hooks";
import { useGetIndicatorsQuery } from "../../store/api/dictionaries.api";
import { useDispatch } from "react-redux";
import { resetRegion } from "../../store/reducer/dataSlice";
import { setParams } from "../../store/reducer/appSlice";

const gridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    gap: '10px',
    // flexDirection: 'column',
    // paddingLeft: '10px',
    // paddingTop: '10px',
    // paddingBottom: '10px',
}

const autocompleteSx = {
    width: '450px',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

const buttonSx = {
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

export const ChooseYear = ({ indicator, type, beforeRedirectHandler }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: indicators } = useGetIndicatorsQuery();
    // const { data: indicators } = useGetAllIndicators();
    const [selectedIndicator, setSelectedIndicator] = useState(null);
    // const [selectedYear, setSelectedYear] = useState(null);

    const autocompleteHandler = (name, value) => {
        if (name === 'indicator') setSelectedIndicator(value ?? null)
        // if (name === 'year') setSelectedYear(value?.id ?? null)
    }

    const indicatorDictionary = useMemo(() => {
        if (!indicators) return [];
        return indicators
            ?.filter((item) => item?.isFullDataset && !item?.isUncountRegions)
            ?.map((item) => ({ id: item.bitrixID, label: item.name }));
    }, [indicators]);


    const redirect = useCallback(() => {
        beforeRedirectHandler?.()
        dispatch(resetRegion());
        if (['treemap', 'piechart', 'race', 'columncountries', 'line'].includes(type)) {
            dispatch(setParams({ indicator: selectedIndicator?.id }));
            navigate(`/indicator/${selectedIndicator?.id}/${type}`);
        }
    }, [beforeRedirectHandler, selectedIndicator, type]);

    useEffect(() => {
        if (indicator) {
            const stateData = { id: indicator?.bitrixID, label: indicator?.name };
            setSelectedIndicator(stateData);
        }
    }, [indicator])

    return (
        <Grid container sx={gridSx}>
            {(!indicators) && <Grid width='100%'><LinearProgress /></Grid>}
            {!!indicators && <>
                <Autocomplete
                    size="small"
                    onChange={(_, value) => autocompleteHandler('indicator', value)}
                    options={indicatorDictionary}
                    sx={autocompleteSx}
                    value={selectedIndicator}
                    renderInput={(params) => <TextField {...params} label="Индикатор" />}
                />
                <Button
                    variant="outlined"
                    color="info"
                    disabled={!selectedIndicator || selectedIndicator?.id === indicator?.bitrixID}
                    onClick={redirect}
                    sx={buttonSx}
                >
                    Перейти
                </Button>
            </>}
        </Grid>
    );
}
