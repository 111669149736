import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  region: null,
  year: null,
  compareRegion: null,
  compareIndicator: null,
};

export const dataSlice = createSlice({
  name: "data",
  reducerPath: "dataSlice",
  initialState,
  reducers: {
    setRegion: (state, action) => {
      state.region = action.payload;
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setCompareRegion: (state, action) => {
      state.compareRegion = action.payload;
    },
    setCompareIndicator: (state, action) => {
      state.compareIndicator = action.payload;
    },
    resetRegion: (state, _) => {
      state.region = null;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setRegion, setYear, setCompareRegion, setCompareIndicator, resetRegion } =
  dataSlice.actions;
