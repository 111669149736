import { Chip, Grid, IconButton, LinearProgress, Tooltip } from "@mui/material";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Header } from "../../components/header";
import { useNavigate, useParams } from "react-router-dom";
import { tooltipClasses } from "@mui/material/Tooltip";

import { styled } from "@mui/material/styles";
import {
  useGetDataByFormula,
  useGetDataByYear,
  useGetOneIndicator,
  usePieGraphChart,
} from "./pieGraph.hooks";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import {
  headerDescriptionTextSx,
  headerGridSx,
  headerTextSx,
} from "./pieGraph.styles";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { ChooseYear } from "../../components/chooseYear/chooseYear";
import { ChooseRegion } from "../../components/chooseRegion/chooseRegion";
import { VisualizationLinks } from "../../components/visualizationLinks/visualizationLinks";
import { usePieGraphParams } from "./pieGraphParams/pieGraphParams.hooks";
import { PieGraphParams } from "./pieGraphParams";
import { nanoid } from "nanoid";
import { CopyHtmlGrid } from "../../components/copyHtmlGrid";
import { preparePieData } from "./pieGraph.utils";
import { ChartTypeLinks } from "../../components/chartTypeLinks";
import {
  useGetDataByIndicatorQuery,
  useGetDataByYearQuery,
} from "../../store/api/vizualization.api";
import { useGetOneIndicatorQuery } from "../../store/api/info.api";
import { useSelector } from "react-redux";
import { useCheckAccess } from "../../hooks/app.hooks";
import { addExportCountListener } from "../../utils/listeners";
import { addExportMenu } from "../../utils/exporting";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

const vizualizationType = "piechart";
export const PieGraph = () => {
  const navigate = useNavigate();
  const chartName = useMemo(() => `visualstat_${nanoid(10)}`, []);
  const { indicatorId } = useParams();
  const selectedRegion = useSelector((state) => state.dataSlice.region);
  const setSelectedRegion = () => { };
  // const [selectedRegion, setSelectedRegion] = useState(null);
  const selectedYear = useSelector((state) => state.dataSlice.year);
  const setSelectedYear = () => { };
  // const [selectedYear, setSelectedYear] = useState(null);
  const { data: indicator } = useGetOneIndicatorQuery(indicatorId, {
    skip: !indicatorId,
  });
  // const { data: indicator } = useGetOneIndicator(indicatorId);
  const { data: dataByYear, isFetching: isLoading } =
    useGetDataByIndicatorQuery({ indicatorId, selectedYear, selectedRegion });
  // const { data: dataByYear, isLoading } = useGetDataByYear(indicatorId, selectedYear, selectedRegion);
  const { params, actions } = usePieGraphParams({ indicator });

  const title = useMemo(() => {
    const defaultYearLabel = indicator?.isOutputLastYearData
      ? dataByYear?.info?.currentYear ?? null
      : "Последний доступный год";
    const yearLabel =
      selectedYear?.label || defaultYearLabel
        ? `| ${selectedYear?.label ?? defaultYearLabel}`
        : "";
    return `${indicator?.name} ${yearLabel} | ${selectedRegion?.label ?? "Весь мир"
      } | ${indicator?.unit}`;
  }, [indicator, selectedYear, selectedRegion, dataByYear]);
  const { htmlDiagram, createChart } = usePieGraphChart(
    chartName,
    dataByYear?.data,
    params,
    title
  );

  // useEffect(() => {
  //     const accessCode = localStorage.getItem('accessCode');
  //     const activationTime = localStorage.getItem('activationTime');
  //     if (!accessCode || !activationTime) {
  //         navigate('/demo-access');
  //     }
  // }, []);
  useCheckAccess();

  useLayoutEffect(() => {
    if (!dataByYear) return;
    const chartData = preparePieData(dataByYear?.data, params);

    am5.addLicense("AM5C404071029");
    let root = am5.Root.new(chartName);

    root.locale["_decimalSeparator"] = ",";
    root.locale["_thousandSeparator"] = " ";

    root.setThemes([am5themes_Animated.new(root)]);

    addExportMenu(root, chartData);
    // am5plugins_exporting.Exporting.new(root, {
    //   menu: am5plugins_exporting.ExportingMenu.new(root, {}),
    //   dataSource: chartData,
    //   filePrefix: "statbase_chart",
    //   pngOptions: {
    //     quality: 0.9,
    //   },
    //   jpgOptions: {
    //     quality: 0.9,
    //   },
    // });

    var container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    if (params.showTitle) {
      container.children.push(
        am5.Label.new(root, {
          text: title,
          fontSize: 20,
          fontWeight: "400",
          x: am5.p50,
          centerX: am5.p50,
        })
      );
    }

    var chart = container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: chartName,
        categoryField: "name",
        valueField: "value",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{tooltipValue}: {value} {unit}",
        }),
      })
    );

    series.data.setAll(chartData);
    if (params.showLegend) {
      series.labels.template.set("visible", false);
      series.ticks.template.set("visible", false);
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.percent(50),
          x: am5.percent(50),
          paddingLeft: 100,
          paddingRight: 100,
          // layout: root.horizontalLayout
        })
      );
      legend.data.setAll(series.dataItems);
    }

    addExportCountListener();
    return () => {
      root.dispose();
    };
  }, [title, chartName, dataByYear, params]);

  return (
    <>
      <Header />
      <Grid container sx={headerGridSx}>
        <ChartTypeLinks type={vizualizationType} />
        <ChooseYear indicator={indicator} type={vizualizationType} />
        {!indicator ? (
          <LinearProgress />
        ) : (
          <>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              mb="10px"
            >
              <Grid container sx={headerTextSx}>
                {title}
                {indicator?.description && (
                  <NoMaxWidthTooltip
                    title={
                      <Grid sx={{ fontSize: "14px" }}>
                        {indicator?.description}
                      </Grid>
                    }
                  >
                    <IconButton>
                      <InfoRoundedIcon color="info" />
                    </IconButton>
                  </NoMaxWidthTooltip>
                )}
              </Grid>
              <Grid width="auto" container gap="5px">
                {indicator?.sources?.map((item) => (
                  <Chip label={item?.name} />
                ))}
              </Grid>
            </Grid>
            {indicator?.text && (
              <Grid container sx={headerDescriptionTextSx}>
                {indicator?.text}
              </Grid>
            )}
          </>
        )}
        <PieGraphParams params={params} actions={actions} />
        <ChooseRegion
          selectedRegion={selectedRegion}
          selectedYear={selectedYear}
          onChangeRegion={setSelectedRegion}
          onChangeYear={setSelectedYear}
          yearFrom={dataByYear?.info?.yearFrom}
          yearTo={dataByYear?.info?.yearTo}
          isOutputLastYearData={indicator?.isOutputLastYearData}
          defaultYear={dataByYear?.info?.currentYear}
          type={vizualizationType}
        />
        <VisualizationLinks
          showColumncountries={indicator?.isFullDataset}
          showPiechart={indicator?.sumValue?.value}
          type={vizualizationType}
        />
        {isLoading && <LinearProgress />}
        <Grid width={"100%"} container gap={"10px"}>
          <div
            id={chartName}
            style={{ width: params.width, height: params.height }}
          ></div>
          <CopyHtmlGrid htmlDiagram={htmlDiagram} createChart={createChart} />
        </Grid>
      </Grid>
    </>
  );
};
