import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyGetDemoAccessQuery } from "../store/api/general.api";

export const useCheckAccess = () => {
  const navigate = useNavigate();
  const [checkAccess] = useLazyGetDemoAccessQuery();

  const { APP_PARAMS } = useGetAppParams();

  useEffect(() => {
    const accessCode = localStorage.getItem("accessCode");
    const activationTime = localStorage.getItem("activationTime");

    // if (!accessCode || !activationTime) {
    //   navigate("/demo-access");
    // }

    if (accessCode) {
      void checkAccess(accessCode)
        .unwrap()
        .then((res) => {
          // const accessIsAllowed = res?.accessIsAllowed;
          // if (!accessIsAllowed) {
          //   navigate("/demo-access");
          // }
        });
    }
  }, []);
};

export const useGetLimits = () => {

  const limits = useMemo(() => {
    const exportLimit = localStorage.getItem("exportLimit");
    const accessCode = localStorage.getItem("accessCode");
    const activationTime = localStorage.getItem("activationTime");
    const exportAvailable = Boolean(accessCode) && Boolean(activationTime);
    const isDemoAccess = !accessCode || !activationTime;

    return {
      exportLimit,
      exportAvailable,
      isDemoAccess,
    }
  }, []);

  return limits;
}

export const useGetAppParams = () => {

  const appParams = useMemo(() => {
    const hostname = window.location.hostname;
    const region = hostname.split('.').pop();
    // const region = 'kz';

    const appIsLimited = ['kz', 'uz', 'kg', 'by'].includes(region);

    const defaultCountries = {
      'kz': '6015',
      'uz': '6119',
      'kg': '6012',
      'by': '6135',
    }
    const defaultIndicators = {
      'kz': '493141',
      'uz': '76867',
      'kg': '81251',
      'by': '81251',
    }

    const defaultCountryId = defaultCountries?.[region] ?? undefined;
    const defaultIndicatorId = defaultIndicators?.[region] ?? undefined;

    return {
      appIsLimited,
      defaultCountryId,
      defaultIndicatorId,
    }
  }, []);

  return { APP_PARAMS: appParams }
}