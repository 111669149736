export const sDataBarDefaultParams = {
    width: "100%",
    height: window.innerWidth < 600 ? "300px" : "600px",
    showLegend: false,
    zoomEnable: true,
    scrollbarEnable: true,
    showTitle: true,
    startXAxisFromZero: false,
    hoverColor: '#297373',
    hideXAxis: false,
    sortByValue: false,
    sortDesc: false,
    isBarChart: true,
};
