import { Chip, Divider, Grid } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resetAnalyticsObject } from "../../store/reducer/sDataSlice";
import { useGetAppParams } from "../../hooks/app.hooks";



export const ChartTypeLinks = ({ type }) => {
    const { indicatorId, sDataIndicatorId } = useParams();
    const { APP_PARAMS } = useGetAppParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const baseParams = useSelector((state) => state.appSlice.baseParams);
    const baseSDataparams = useSelector((state) => state.appSlice.baseSDataparams);

    const pageType = useMemo(() => {
        if (type == 'column') {
            return 'countries';
        }
        if (['treemap', 'piechart', 'race', 'columncountries', 'map', 'line'].includes(type)) {
            return 'worldAndRegions';
        }
        if (type == 'sdata') {
            return 'sdata';
        }
    }, [type]);

    const onClick = useCallback((clickType) => {
        const baseIndicator = 12276;
        const baseSDataIndicator = 505454;

        if (pageType === clickType) return;

        dispatch(resetAnalyticsObject());

        if (APP_PARAMS.appIsLimited) {
            if (clickType === 'countries') {
                navigate(`/indicator/${baseParams.indicator}`);
            }
            if (clickType === 'sdata') {
                navigate(`/indicator/${baseSDataparams.indicator}/sdata`);
            }
        } else {
            if (clickType === 'countries') {
                navigate(`/indicator/${baseParams.indicator}/country/${baseParams.country}`);
            }
            if (clickType == 'worldAndRegions') {
                navigate(`/indicator/${baseParams.indicator}/treemap`);
            }
            if (clickType === 'sdata') {
                navigate(`/indicator/${baseSDataparams.indicator}/country/${baseSDataparams.country}/sdata`);
            }
        }
    }, [indicatorId, pageType, dispatch, baseParams, baseSDataparams, APP_PARAMS]);

    return (
        <Grid container flexDirection='row' alignItems='flex-start' gap='15px'>
            <Grid container gap='5px'>
                <Chip
                    key={`countries`}
                    color={(pageType === 'countries') ? 'primary' : 'default'}
                    label={APP_PARAMS.appIsLimited ? 'По стране' : 'По странам'}
                    onClick={() => onClick('countries')}
                />
                <Chip
                    key={`sdata`}
                    color={(pageType === 'sdata') ? 'primary' : 'default'}
                    label={APP_PARAMS.appIsLimited ? 'С аналитикой по регионам, товарам и пр.' : 'По странам с аналитикой'}
                    onClick={() => onClick('sdata')}
                />
                {!APP_PARAMS.appIsLimited && <Chip
                    key={`worldAndRegions`}
                    color={(pageType === 'worldAndRegions') ? 'primary' : 'default'}
                    label='По миру и регионам'
                    onClick={() => onClick('worldAndRegions')}
                />}
            </Grid>
        </Grid >
    );
}
