import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useCallback, useEffect, useMemo } from "react";
import { useGetOrganizations } from "./chooseRegion.hooks";
import { useGetOrganizationsQuery } from "../../store/api/dictionaries.api";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompareIndicator,
  setCompareRegion,
  setRegion,
  setYear,
} from "../../store/reducer/dataSlice";
import {
  useGetCompareIndicatorsQuery,
  useGetOrganizationCountriesQuery,
} from "../../store/api/info.api";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

const gridSx = {
  border: "1px solid #e0e0e0",
  borderRadius: "10px",
  padding: "10px",
  gap: "10px",
};

const autocompleteSx = {
  // width: "450px",
  "@media (max-width: 600px)": {
    width: "100%",
  },
};

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    'margin-left': '10%',
  },
});

const paramsTitleSx = {
  fontSize: "32px",
  fontWeight: "600",
  mb: "5px",
  width: "auto",
  "@media (max-width: 600px)": {
    fontSize: "24px",
    justifyContent: "center",
  },
};

export const ChooseCompareRegionV2 = ({
  compareRegionAvailable,
  indicatorId,
}) => {
  const dispatch = useDispatch();

  const compareRegion = useSelector((state) => state.dataSlice.compareRegion);
  const compareIndicator = useSelector(
    (state) => state.dataSlice.compareIndicator
  );

  const { data: organizations } = useGetOrganizationsQuery();
  const {
    data: organizationCountries,
    isFetching: isLoadingOrganizationCountries,
  } = useGetOrganizationCountriesQuery(compareRegion?.id, {
    skip: !compareRegion?.id,
  });
  const { data: indicatorsCompare } = useGetCompareIndicatorsQuery(
    indicatorId,
    {
      skip: !indicatorId,
    }
  );

  const indicatorsCompareDictionary = useMemo(
    () =>
      indicatorsCompare
        ?.filter((item) => item?.isFullDataset)
        ?.map((item) => ({
          id: item.bitrixID,
          label: item.name,
        })),
    [indicatorsCompare]
  );

  const tooltipText = useMemo(() => {
    if (!compareIndicator || !indicatorsCompare) return null;
    const findedIndicator = indicatorsCompare?.find((item) => item.bitrixID === compareIndicator.id);
    return findedIndicator?.description;
  }, [compareIndicator, indicatorsCompare]);

  const onChangeRegion = useCallback(
    (value) => {
      dispatch(setCompareIndicator(null));
      dispatch(setCompareRegion(value));
    },
    [dispatch]
  );

  const onChangeIndicator = useCallback(
    (value) => {
      dispatch(setCompareRegion(null));
      dispatch(setCompareIndicator(value));
    },
    [dispatch]
  );

  const regionDictionary = useMemo(() => {
    if (!organizations) return [];
    const filtered = organizations
      ?.filter((item) => Boolean(item.agrType))
      ?.sort((a, b) => a.sort - b.sort);
    return filtered?.map((item) => ({ id: item.bitrixId, label: item.name }));
  }, [organizations]);

  const organizationCountriesText = useMemo(() => {
    if (!compareRegion) return null;
    const names = organizationCountries?.map((item) => item?.name);
    return `Страны: ${names?.join(", ")}`;
  }, [compareRegion, organizationCountries]);

  return (
    <Grid container width="auto" flexGrow='1' flexDirection="column" gap="10px">
      {!organizations && (
        <Grid width="100%">
          <LinearProgress />
        </Grid>
      )}
      <Grid container sx={paramsTitleSx}>
        Сравнить
      </Grid>
      <Grid container>
        <Autocomplete
          size="small"
          fullWidth
          onChange={(_, value) => onChangeRegion(value)}
          options={regionDictionary}
          sx={autocompleteSx}
          value={compareRegion}
          disabled={!compareRegionAvailable}
          renderInput={(params) => <TextField {...params} label="Регион" />}
        />
        {Boolean(organizationCountriesText) && (
          <>
            {isLoadingOrganizationCountries ? (
              <CircularProgress size="26px" />
            ) : (
              <NoMaxWidthTooltip
                title={
                  <Grid sx={{ fontSize: "14px" }}>
                    {organizationCountriesText}
                  </Grid>
                }
              >
                <IconButton>
                  <InfoRoundedIcon color="info" />
                </IconButton>
              </NoMaxWidthTooltip>
            )}
          </>
        )}
      </Grid>
      <Grid container wrap="nowrap">
        {indicatorsCompareDictionary?.length > 0 && <Autocomplete
          size="small"
          fullWidth
          onChange={(_, value) => onChangeIndicator(value)}
          options={indicatorsCompareDictionary ?? []}
          sx={autocompleteSx}
          value={compareIndicator}
          // disabled={!!compareRegionAvailable}
          renderInput={(params) => <TextField {...params} label="Индикатор" />}
        />}
        {tooltipText && <NoMaxWidthTooltip
          title={
            <Grid sx={{ fontSize: "14px" }}>
              {tooltipText}
            </Grid>
          }
        >
          <IconButton>
            <InfoRoundedIcon color="info" />
          </IconButton>
        </NoMaxWidthTooltip>}
      </Grid>
    </Grid>
  );
};
