import { useCallback, useState } from "react";
import { getSDataChartTitle, prepareSDataWithTree } from "../sDataPage.utils";
import { isColor } from "../../../utils/chart.utils";

export const useSDataColumns = (
    chartName,
    data,
    params,
    selectedAnalyticsObjects,
    compareSData,
    formulaSData,
    sliderParams
) => {
    const [htmlDiagram, setHtmlDiagram] = useState("");

    const createChart = useCallback(() => {
        if (!data) return;
        const chartDataArray = prepareSDataWithTree(data, selectedAnalyticsObjects, compareSData, formulaSData, sliderParams);
        const chartTitle = getSDataChartTitle({ data, formulaSData });
        if (chartDataArray.length === 0) return;

        let strArray = [
            `<script src="https://cdn.amcharts.com/lib/5/index.js"></script>\r\n`,
            `<script src="https://cdn.amcharts.com/lib/5/xy.js"></script>\r\n`,
            `<script src="//cdn.amcharts.com/lib/5/themes/Animated.js"></script>\r\n`,
            `<div id="${chartName}" style="width: ${params.width}; height: ${params.height}"></div>\r\n`,
            `<script>\r\n`,
        ];

        strArray.push(`\tvar jsonChartData = '${JSON.stringify(chartDataArray)}';\r\n`);
        strArray.push(`\tvar chartDataArray = JSON.parse(jsonChartData);\r\n`);

        strArray.push(`\tvar root = am5.Root.new('${chartName}');\r\n`);
        strArray.push(`\troot.setThemes([
            am5themes_Animated.new(root)
            ]);\r\n`);

        strArray.push(`\troot.locale["_decimalSeparator"] = ",";\r\n`);
        strArray.push(`\troot.locale["_thousandSeparator"] = " ";\r\n`);

        strArray.push(`\tlet chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                ${params.zoomEnable
                ? `
                        wheelX: "panX",
                        wheelY: "zoomX",
                    `
                : ""}
                pinchZoomX: true,
                layout: root.verticalLayout,
            })
        );\r\n`);

        if (params.showTitle) {
            strArray.push(`\tchart.topAxesContainer.children.push(
                am5.Label.new(root, {
                    text: '${chartTitle}',
                    fontSize: 20,
                    fontWeight: "400",
                    x: am5.p50,
                    centerX: am5.p50,
                })
            );\r\n`);
        }

        if (params.scrollbarEnable) {
            strArray.push(
                `\tchart.set("scrollbarX", am5.Scrollbar.new(root, { orientation: "horizontal" }));\r\n`
            );
        }

        strArray.push(
            `\tlet cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));\r\n`
        );
        strArray.push(`\tcursor.lineY.set("visible", false);\r\n`);

        strArray.push(`\tlet yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
                ${params?.startYAxisFromZero ?
                `
                    min: 0,
                    strictMinMax: true,
                ` : ""
            }
                forceHidden: ${params?.hideYAxis}
            })
        );\r\n`);

        strArray.push(`\tvar xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                renderer: am5xy.AxisRendererX.new(root, {}),
                categoryField: "columnName"
            })
        );\r\n`);

        strArray.push(`\txAxis.data.setAll(chartDataArray[0].seriesData);\r\n`);

        strArray.push(`\tchartDataArray.forEach((chartData) => {\r\n`);
        strArray.push(`\tconst { seriesName, seriesData } = chartData;\r\n`);
        strArray.push(`\tconst series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: seriesName,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    categoryXField: "columnName",
                    minBulletDistance: 50,
                    sequencedInterpolation: true,
                    tooltip: am5.Tooltip.new(root, {
                        labelText: '{tooltipLabel} | {categoryX}: {valueY} {unit}',
                    }),
                })
            );\r\n`);
        strArray.push(`\tseries.data.setAll(seriesData);\r\n`);
        strArray.push(`\tseries.appear(500);\r\n`);
        strArray.push(`\tseries.columns.template.setAll({ cornerRadiusTL: 0, cornerRadiusTR: 0 });\r\n`);
        strArray.push(`\tseries.columns.template.set("interactive", true);\r\n`);
        if (isColor(params.color)) {
            strArray.push(`\tseries.columns.template.states.create("hover", {
                    fill: am5.color('${params.hoverColor}'),
                    stroke: am5.color('${params.hoverColor}'),
                });\r\n`);
        }
        strArray.push(`\t});\r\n`);

        strArray.push(`\tvar yRenderer = yAxis.get("renderer");\r\n`);
        strArray.push(`\tyRenderer.labels.template.setAll({
            fontSize: "0.7em",
            rotation: -60,
            centerY: am5.p50
        });\r\n`);

        if (params.showLegend) {
            strArray.push(`\tvar legend = chart.children.push(
                am5.Legend.new(root, {
                    centerX: am5.percent(50),
                    x: am5.percent(50),
                })
            );\r\n`);
            strArray.push(`\tlegend.data.setAll(chart.series.values);\r\n`);
        }

        strArray.push(`\tseries.appear(500);\r\n`);

        strArray.push(`</script>`);
        setHtmlDiagram(strArray.join(""));
    }, [
        chartName,
        data,
        params,
        selectedAnalyticsObjects,
        compareSData,
        formulaSData,
        sliderParams
    ]);

    return { htmlDiagram, createChart };
};