import { Grid } from "@mui/material";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { useLayoutEffect, useMemo } from "react";
import { nanoid } from "nanoid";
import { getAnalyticsObjectsTree, getSDataChartTitle, getSDataTreemapYear, prepareSData, prepareSDataTreemap, prepareSDataTreemapV2, prepareSDataTreemapV3 } from "../sDataPage.utils";
import { AnalyticsObjectsSelector, AnalyticsObjectsTreeSelector } from "../../../components/analyticsObjectsSelector";
import { useSDataParams } from "../sDataPage.hooks";
import { sDataTreemapDefaultParams, SDataTreemapParams } from "./sDataTreemapParams";
import { SDataChartType } from "../../../components/sDataChartType";
import { SDataYearSelect } from "../../../components/sDataYearSelect";
import { useSelector } from "react-redux";
import { CopyHtmlGrid } from "../../../components/copyHtmlGrid";
import { useSDataTreemap } from "./sDataTreemap.hooks";
import { addExportCountListener } from "../../../utils/listeners";
import { addExportMenu } from "../../../utils/exporting";

const gridParamsSx = {
    flexDirection: 'row',
    gap: '5px',
    height: '310px',
    flexWrap: "nowrap",
    '@media (max-width: 600px)': {
        flexDirection: 'column',
        height: 'unset',
        // flexWrap: 'wrap'
    }
}

export const SDataTreemap = ({ data, selectedAnalyticsObjects }) => {
    const chartName = useMemo(() => `visualstat_${nanoid(10)}`, []);
    const { params, actions } = useSDataParams(sDataTreemapDefaultParams);
    const selectedYear = useSelector((state) => state.sDataSlice.year);

    const analyticsObjectsTree = useMemo(() => getAnalyticsObjectsTree(data?.analyticsObjects ?? []), [data]);

    const hasGroups = useMemo(() => {
        return analyticsObjectsTree?.some((item) => item.childrens.length > 0);
    }, [analyticsObjectsTree]);

    const { htmlDiagram, createChart } = useSDataTreemap(
        chartName,
        data,
        params,
        selectedAnalyticsObjects,
        selectedYear,
    );

    useLayoutEffect(() => {
        if (!data) return;
        const year = getSDataTreemapYear(data, selectedYear);
        // const chartDataArray = prepareSDataTreemap(data, year, params, selectedAnalyticsObjects);
        const chartDataArray = prepareSDataTreemapV3(data, year, params, selectedAnalyticsObjects);
        const chartTitle = getSDataChartTitle({ data, year });
        // if (chartDataArray.length === 0) return;

        const chartData = [
            {
                columnName: data?.indicator?.name,
                children: chartDataArray,
            },
        ];

        am5.addLicense("AM5C404071029");
        let root = am5.Root.new(chartName);

        root.locale["_decimalSeparator"] = ",";
        root.locale["_thousandSeparator"] = " ";

        root.setThemes([am5themes_Animated.new(root)]);

        addExportMenu(root, chartDataArray);
        // am5plugins_exporting.Exporting.new(root, {
        //     menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        //     dataSource: chartDataArray,
        //     filePrefix: "statbase_chart",
        //     pngOptions: {
        //         quality: 0.9,
        //     },
        //     jpgOptions: {
        //         quality: 0.9,
        //     },
        // });

        var container = root.container.children.push(
            am5.Container.new(root, {
                width: am5.percent(100),
                height: am5.percent(100),
                layout: root.verticalLayout,
            })
        );

        if (params.showTitle) {
            container.children.push(
                am5.Label.new(root, {
                    text: chartTitle,
                    fontSize: 20,
                    fontWeight: "400",
                    x: am5.p50,
                    centerX: am5.p50,
                })
            );
        }

        var series = container.children.push(
            am5hierarchy.Treemap.new(root, {
                categoryField: "columnName",
                valueField: "value",
                childDataField: "children",
                layoutAlgorithm: "binary",
                // sort: params?.sortDesc ? 'ascending' : 'descending',
                //------------------------
                downDepth: 1,
                upDepth: 0,
                initialDepth: 1,
                //------------------------
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{tooltipLabel}: {sum} {unit}",
                }),
            })
        );

        container.children.moveValue(
            am5hierarchy.BreadcrumbBar.new(root, {
                series: series
            }), 0
        );

        series.data.setAll(chartData);
        series.set("selectedDataItem", series.dataItems[0]);

        addExportCountListener();
        return () => {
            root.dispose();
        };
    }, [
        chartName,
        data,
        params,
        selectedAnalyticsObjects,
        selectedYear,
    ]);

    return (
        <>
            <Grid container sx={gridParamsSx}>
                {/* <AnalyticsObjectsSelector
                    analyticsObjects={data?.analyticsObjects}
                    label={data?.indicator?.elementsName}
                /> */}
                {hasGroups && <AnalyticsObjectsTreeSelector
                    analyticsObjects={analyticsObjectsTree}
                    label={data?.indicator?.elementsName}
                    hideElements={true}
                />}
                <SDataTreemapParams params={params} actions={actions} />
            </Grid>
            <SDataYearSelect data={data} />
            <SDataChartType isSumValue={data?.indicator?.sumValue} isSumValuesGraph={data?.indicator?.isSumValuesGraph} />
            <Grid width={"100%"} container gap={"10px"}>
                <div
                    id={chartName}
                    style={{ width: params.width, height: params.height }}
                ></div>
                <CopyHtmlGrid htmlDiagram={htmlDiagram} createChart={createChart} />
            </Grid>
        </>
    );
};
