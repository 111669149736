import { useCallback, useState } from "react";
import { prepareMapData } from "./mapGraph.utils";

export const useMapGraphChart = (chartName, data, params, title) => {

    const [htmlDiagram, setHtmlDiagram] = useState('');

    const createChart = useCallback(() => {
        if (!data) return;
        const chartData = prepareMapData(data, params);

        let strArray = [
            `<script src="https://cdn.amcharts.com/lib/5/index.js"></script>\r\n`,
            `<script src="https://cdn.amcharts.com/lib/5/map.js"></script>\r\n`,
            `<script src="https://cdn.amcharts.com/lib/5/geodata/worldLow.js"></script>\r\n`,
            `<script src="//cdn.amcharts.com/lib/5/themes/Animated.js"></script>\r\n`,
            `<div id="${chartName}" style="width: ${params.width}; height: ${params.height}"></div>\r\n`,
            `<script>\r\n`
        ];

        strArray.push(`\tvar root = am5.Root.new('${chartName}');\r\n`);

        strArray.push(`\tvar colors = am5.ColorSet.new(root, {});\r\n`);
        strArray.push(`\tvar jsonChartData = '${JSON.stringify(chartData)}';\r\n`);
        strArray.push(`\tvar chartData = JSON.parse(jsonChartData);\r\n`);
        strArray.push(`\tlet data = chartData.map((item, index) => ({ ...item, circleTemplate: { fill: colors.getIndex(index) } }));\r\n`);

        strArray.push(`\troot.setThemes([
            am5themes_Animated.new(root)
        ]);\r\n`);

        strArray.push(`\troot.locale["_decimalSeparator"] = ",";\r\n`);
        strArray.push(`\troot.locale["_thousandSeparator"] = " ";\r\n`);

        strArray.push(`\tvar container = root.container.children.push(
            am5.Container.new(root, {
                width: am5.percent(100),
                height: am5.percent(100),
                layout: root.verticalLayout,
            })
        );\r\n`);

        if (params.showTitle) {
            strArray.push(`\tcontainer.children.push(am5.Label.new(root, {
                text: '${title}',
                fontSize: 20,
                fontWeight: "400",
                x: am5.p50,
                centerX: am5.p50,
            }));\r\n`);
        }

        strArray.push(`\tlet chart = container.children.push(am5map.MapChart.new(root, {
            panX: "rotateX",
            panY: "rotateY",
            projection: am5map.geoMercator()
        }));\r\n`);

        strArray.push(`\tvar backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));\r\n`);
        strArray.push(`\tbackgroundSeries.mapPolygons.template.setAll({
            fill: root.interfaceColors.get("alternativeBackground"),
            fillOpacity: 0,
            strokeOpacity: 0
        });\r\n`);

        strArray.push(`\tbackgroundSeries.data.push({
            geometry: am5map.getGeoRectangle(90, 180, -90, -180)
        });\r\n`);
        strArray.push(`\tvar polygonSeries = chart.series.push(
            am5map.MapPolygonSeries.new(root, {
                geoJSON: am5geodata_worldLow
            })
        );\r\n`);
        strArray.push(`\tpolygonSeries.mapPolygons.template.setAll({
            fill: root.interfaceColors.get("alternativeBackground"),
            fillOpacity: 0.15,
            strokeWidth: 0.5,
            stroke: root.interfaceColors.get("background")
        });\r\n`);
        strArray.push(`\tvar circleTemplate = am5.Template.new({
            tooltipText: "{tooltipValue}: {value} {unit}"
        });\r\n`);
        strArray.push(`\tvar bubbleSeries = chart.series.push(
            am5map.MapPointSeries.new(root, {
                calculateAggregates: true,
                valueField: "value",
                polygonIdField: "id"
            })
        );\r\n`);
        strArray.push(`\tbubbleSeries.bullets.push(function () {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    radius: 10,
                    templateField: "circleTemplate"
                }, circleTemplate)
            });
        });\r\n`);
        strArray.push(`\tbubbleSeries.set("heatRules", [{
            target: circleTemplate,
            min: 3,
            max: 30,
            key: "radius",
            dataField: "value"
        }]);\r\n`);
        strArray.push(`\tbubbleSeries.data.setAll(data);\r\n`);
        strArray.push(`\tvar cont = chart.children.push(am5.Container.new(root, {
            layout: root.horizontalLayout,
            x: 20,
            y: 40
        }));
\r\n`);
        strArray.push(`\tcont.children.push(am5.Label.new(root, {
            centerY: am5.p50,
            text: "Map"
        }));\r\n`);
        strArray.push(`\tvar switchButton = cont.children.push(
            am5.Button.new(root, {
                themeTags: ["switch"],
                centerY: am5.p50,
                icon: am5.Circle.new(root, {
                    themeTags: ["icon"]
                })
            })
        );\r\n`);
        strArray.push(`\tswitchButton.on("active", function () {
            if (!switchButton.get("active")) {
                chart.set("projection", am5map.geoMercator());
                backgroundSeries.mapPolygons.template.set("fillOpacity", 0);
            } else {
                chart.set("projection", am5map.geoOrthographic());
                backgroundSeries.mapPolygons.template.set("fillOpacity", 0.1);
            }
        });\r\n`);
        strArray.push(`\tcont.children.push(
            am5.Label.new(root, {
                centerY: am5.p50,
                text: "Globe"
            })
        );\r\n`);
        strArray.push(`\tchart.appear(1000, 100);\r\n`);


        strArray.push(`</script>`);
        setHtmlDiagram(strArray.join(''));
    }, [chartName, data, params, title]);

    return { htmlDiagram, createChart }
}