import { useCallback, useEffect, useMemo } from "react";
import { Autocomplete, Chip, CircularProgress, Grid, IconButton, LinearProgress, TextField, Tooltip, tooltipClasses } from "@mui/material";
import { useGetAllOrganizations, useGetCountryOrganizations, useGetFormulasIndicator, useGetOrganizationCountries } from "./formulaSelector.hooks";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { styled } from '@mui/material/styles';
import { useGetLimits } from "../../hooks/app.hooks";
import { DemoAccessModal } from "../../modals/demoAccessModal/demoAccessModal";
import { useDemoAccessModal } from "../../modals/demoAccessModal/demoAccessModal.hooks";
// import { useGetFormulasIndicator } from "../../pages/BaseTestChart/baseTestChart.hooks.js";

const textGridSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'left',
    width: '100%',
    // gap: '10px',
    // flexDirection: 'column',
    // paddingLeft: '10px',
    // paddingTop: '10px',
    // paddingBottom: '10px',
}

const organizationSx = {
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    textAlign: 'left',
    width: '100%',
    gap: '10px',
    alignItems: 'center',
}

const autocompleteSx = {
    width: '30%',
    '@media (max-width: 600px)': {
        width: '100%',
    }
}

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

export const FormulaSelector = ({
    formulaId,
    indicatorId,
    countryId,
    indicatorType,
    indicator,
    organizationId,
    disabled,
    onChange,
    onChangeOrganization,
    organization,
}) => {
    const { isDemoAccess } = useGetLimits();
    const { data: formulas, isLoading: formulasIsLoading } = useGetFormulasIndicator(indicatorId);
    const { data: organizations } = useGetCountryOrganizations(countryId);
    const { data: organizationCountries, isLoading: isLoadingOrganizationCountries } = useGetOrganizationCountries(organizationId === 'all' ? null : organizationId);

    const { isOpen, openModal, closeModal } = useDemoAccessModal();

    const organizationCountriesText = useMemo(() => {
        const names = organizationCountries?.map((item) => item?.name);
        return `Страны: ${names?.join(', ')}`;
    }, [organizationCountries]);

    const organizationDictionary = useMemo(() => {
        let dictionary = [{ id: 'all', label: 'Весь мир' }];
        if (!organizations) return dictionary;

        const sortedOrganization = organizations.sort((a, b) => a?.sort - b?.sort);

        sortedOrganization.forEach((item) => {
            if (item?.agrType !== null) {
                dictionary.push({ id: item.bitrixID, label: item.name })
            }
        });
        return dictionary;
    }, [organizations]);

    const onChangeHandler = useCallback((_, formulaId) => {
        if (isDemoAccess) {
            openModal();
            return;
        }

        // setSelectedFormula(formulaId);
        const currentFormula = formulas?.find((item) => item.bitrixID === formulaId);
        if (formulaId !== 'proc') {
            onChangeOrganization?.({ id: 'all', label: 'Весь мир' });
        }
        onChange?.(currentFormula ?? formulaId);
    }, [onChange, formulas, isDemoAccess, openModal]);

    const onChangeOrganizationHandler = useCallback((_, organizationId) => {
        onChangeOrganization?.(organizationId);
    }, [onChangeOrganization]);

    // const currentFormula = useMemo(() => {
    //     if (selectedFormula == 0 || !selectedFormula) return null;
    //     return formulas?.find((item) => item.bitrixID === selectedFormula);
    // }, [formulas, selectedFormula]);

    const currentFormula = useMemo(() => {
        if (formulaId == 0) return null;
        return formulas?.find((item) => item.bitrixID === formulaId);
    }, [formulas, formulaId]);

    // const defaultButtons = useMemo(() => {
    //     let baseData = [<ToggleButton value={0}>Базовые данные</ToggleButton>];
    //     if (['sum', 'perUnit'].includes(indicatorType)) {
    //         baseData.push(<ToggleButton value={'sum'}>Изменение (сумма)</ToggleButton>);
    //         baseData.push(<ToggleButton value={'izmproc'}>Изменение (проценты)</ToggleButton>);
    //     }
    //     return baseData;
    // }, [indicatorType])

    const defaultButtons = useMemo(() => {
        let baseData = [];
        if (indicatorType === 'sum' && indicator?.isFullDataset) {
            baseData.push({ value: 'proc', name: 'Доля' });
        }
        if (['sum', 'perUnit'].includes(indicatorType)) {
            baseData.push({ value: 'sum', name: 'Изменение (сумма)' });
            baseData.push({ value: 'izmproc', name: 'Изменение (проценты)' });
        }
        return baseData;
    }, [indicatorType, indicator])

    return (
        <>
            <Grid container flexDirection='column' alignItems='flex-start' gap='5px'>
                {formulasIsLoading && <Grid width='100%'><LinearProgress /></Grid>}
                {
                    (!formulasIsLoading) &&
                    <Grid container gap='5px'>
                        <Chip
                            key={`base_data_0`}
                            color={(!formulaId || formulaId == 0) ? 'primary' : 'default'}
                            label='Базовые данные'
                            disabled={disabled}
                            onClick={() => onChangeHandler(null, 0)}
                        />
                        {defaultButtons.map((item, index) =>
                            <Chip
                                key={`base_${index}`}
                                color={item.value === formulaId ? 'primary' : 'default'}
                                label={item.name}
                                disabled={disabled}
                                onClick={() => onChangeHandler(null, item.value)}
                            />
                        )}
                        {formulas?.sort((a, b) => a?.sort - b?.sort)?.map((item, index) =>
                            <Chip
                                key={`special_${item.bitrixID}_${index}`}
                                color={item.bitrixID === formulaId ? 'primary' : 'default'}
                                label={item.name}
                                disabled={disabled}
                                onClick={() => onChangeHandler(null, item.bitrixID)}
                            />
                        )}
                    </Grid>
                }
                {/* {(!formulasIsLoading && formulas?.length > 0) && <ToggleButtonGroup
                color="primary"
                value={formulaId ?? 0}
                exclusive
                onChange={onChangeHandler}
                aria-label="Platform"
                sx={{ '.MuiButtonBase-root': { fontSize: '12px' } }}
                disabled={disabled}
            > */}
                {/* <ToggleButton value={0}>Базовые данные</ToggleButton>
                <ToggleButton value={'sum'}>Изменение сумма</ToggleButton>
                <ToggleButton value={'izmproc'}>Изменение проценты</ToggleButton> */}
                {/* {defaultButtons?.map((item) => item)} */}
                {/* {defaultButtons?.map((item) => <ToggleButton value={item.value}>{item.name}</ToggleButton>)} */}
                {/* {formulas?.map((item) => <ToggleButton value={item.bitrixID}>{item.name}</ToggleButton>)} */}
                {/* <ToggleButton value="android">Android</ToggleButton>
                <ToggleButton value="ios">iOS</ToggleButton> */}
                {/* </ToggleButtonGroup>} */}
                {
                    formulaId === 'proc' && <Grid container sx={organizationSx}>
                        <Autocomplete
                            size="small"
                            sx={autocompleteSx}
                            onChange={onChangeOrganizationHandler}
                            options={organizationDictionary}
                            value={organization}
                            // defaultValue={}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} label="Организация" />}
                        />
                        {Boolean(organizationCountries) &&
                            <>
                                {isLoadingOrganizationCountries ? <CircularProgress size='26px' /> :
                                    <NoMaxWidthTooltip
                                        title={
                                            <Grid sx={{ fontSize: '14px' }}>
                                                {organizationCountriesText}
                                            </Grid>
                                        }
                                    >
                                        <IconButton>
                                            <InfoRoundedIcon color="info" />
                                        </IconButton>
                                    </NoMaxWidthTooltip>
                                }
                            </>
                        }
                    </Grid>
                }
                {currentFormula?.text && <Grid sx={textGridSx}>{currentFormula?.text}</Grid>}
            </Grid >
            <DemoAccessModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
}
